import { CaretRight } from 'phosphor-react';
import { Link } from 'react-router-dom';

import { Progress } from '~components/StoreGuidedSetup';
import { getPercentage } from '~containers/lazy/StoreGuidedSetup';
import { useGuidedStoreSetup } from '~hooks/useGuidedStoreSetup';

export const GuidedStoreSetup = () => {
	const { nextStep, checklistState } = useGuidedStoreSetup();

	if (!nextStep) {
		return null;
	}

	return (
		<div className="mx-2 mt-3 flex items-center border-b border-gray-700 pb-3">
			<Progress
				darkMode
				percentage={getPercentage({
					...checklistState?.build,
					...checklistState?.connect,
					...checklistState?.deploy,
				})}
			/>

			<div className="ml-3 flex flex-col justify-between text-sm font-bold leading-5 ">
				Guided store setup
				<Link
					to="setup"
					className="mt-1 flex items-center text-xs font-normal leading-4 text-gray-300 transition-colors hover:text-white"
				>
					View all steps <CaretRight className="ml-1 h-3 w-3" />
				</Link>
			</div>
		</div>
	);
};
