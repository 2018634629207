import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { getViewLink } from '~lib';
import { StoreQuery, useStoreQuery } from '~graphql/api/hooks';
import { queryWrapper } from '~lib/queryWrappers/query';

import { useOrganization } from './OrganizationProvider';

type StoreContextProps = {
	store: StoreQuery['storeBySlug'] | undefined;
	storeUrl: string;
	previewUrl: string;
	isLoading: boolean;
};

export const StoreContext = createContext<StoreContextProps>(undefined as any);

export const StoreProvider = ({
	children,
	storeSlug,
}: {
	children: ReactNode;
	storeSlug?: string;
}) => {
	const params = useParams();
	const { organization } = useOrganization();

	const { data, isLoading } = queryWrapper(useStoreQuery, {
		organization: params.organization!,
		store: storeSlug ?? params.store!,
	})(
		{ slug: storeSlug ?? params!.store! },
		{
			enabled:
				(Boolean(storeSlug) || Boolean(params?.store)) &&
				Boolean(organization?.slug),
		},
	);

	const storeUrl = getViewLink(data?.storeBySlug?.slug);
	const previewUrl = getViewLink(data?.storeBySlug?.slug, true);

	return (
		<StoreContext.Provider
			value={{
				store: data?.storeBySlug,
				previewUrl,
				isLoading,
				storeUrl,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};

export const useStore = () => useContext(StoreContext);
