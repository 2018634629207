export const ErrorFooter = () => (
	<footer className="mx-auto w-full max-w-7xl shrink-0 px-4 sm:px-6 lg:px-8">
		<nav className="flex justify-center space-x-4">
			<a
				target="_blank"
				rel="noopener noreferrer"
				className="border-r px-8 font-medium"
				href={import.meta.env.VITE_SUPPORT_URL}
			>
				Support
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				className="border-r px-8 font-medium"
				href={import.meta.env.VITE_STATUS_URL}
			>
				Status
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				className="px-8 font-medium"
				href={import.meta.env.VITE_TWITTER_URL}
			>
				Twitter
			</a>
		</nav>
	</footer>
);
