import { Switch } from '@headlessui/react';
import cx from 'classnames';

import { Checkbox, Progress, SetupStepDisclosure } from '~components';
import { ChecklistStatus, ChecklistStep } from '~graphql/api/hooks';
import { useGuidedStoreSetup } from '~hooks/useGuidedStoreSetup';

export const getPercentage = (steps) => {
	if (!steps) {
		return 0;
	}

	const doneSteps = Object.values(steps).filter(
		(item) => item !== ChecklistStatus.NotVisited,
	).length;

	const totalSteps = Object.values(steps).length;

	return Math.round((doneSteps / totalSteps) * 100);
};

const StoreGuidedSetupChecklist = () => {
	const {
		nextStep,
		checklistState,
		skipOnboardingFlow,
		onboardingFlowSkipped,
	} = useGuidedStoreSetup();

	const selected = checklistState
		? Object.keys(checklistState).find((stepState) => {
				if (
					!!Object.keys(checklistState[stepState]).find(
						(singleStep) =>
							singleStep.toLowerCase() ===
							nextStep?.replaceAll('_', '').toLowerCase(),
					)
				) {
					return true;
				}
				return false;
		  })
		: undefined;

	return (
		<div className="content-container mt-8 h-full">
			<div className="box-border rounded-lg border border-solid border-gray-100 bg-white px-6 py-8 shadow-sm">
				<div className="mt-4 flex">
					<div className="mr-4 mt-3 flex min-h-full flex-col items-center">
						<Progress
							percentage={getPercentage({
								...checklistState?.connect,
								createAccount: ChecklistStatus.Done,
								createStore: ChecklistStatus.Done,
							})}
						/>
						<div className="h-full">
							<div
								className={cx(
									getPercentage({
										...checklistState?.connect,
										createAccount: ChecklistStatus.Done,
										createStore: ChecklistStatus.Done,
									}) === 100
										? 'border-solid border-primary-500'
										: 'border-dashed border-l-gray-300',
									'h-[calc(100%+12px)] w-0 border-l',
								)}
							></div>
						</div>
					</div>
					<SetupStepDisclosure
						title="Connect"
						description="Setup the essentials to start building your store"
						defaultOpen={selected === 'connect'}
					>
						<Checkbox step="CREATE_ACCOUNT" checked={true} />
						<Checkbox step={ChecklistStep.CreateStore} checked={true} />
						<Checkbox
							step={ChecklistStep.ConnectShopify}
							checked={
								checklistState?.connect.connectShopify !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.ConnectStoryblok}
							checked={
								checklistState?.connect.connectStoryblok !==
								ChecklistStatus.NotVisited
							}
						/>
					</SetupStepDisclosure>
				</div>
				<div className="flex">
					<div className="mr-4 mt-3 flex min-h-full flex-col items-center">
						<Progress percentage={getPercentage(checklistState?.build)} />
						<div className="h-full">
							<div className="h-[calc(100%+12px)] w-0 border-l border-dashed border-l-gray-300"></div>
						</div>
					</div>
					<SetupStepDisclosure
						title="Build"
						description="Build your store, your way"
						defaultOpen={selected === 'build'}
					>
						<Checkbox
							step={ChecklistStep.InviteTeamMembers}
							checked={
								checklistState?.build?.inviteTeamMembers !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.AddBrandAssets}
							checked={
								checklistState?.build?.addBrandAssets !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.AddStorefrontBlock}
							checked={
								checklistState?.build?.addStoreFrontBlock !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.EditStorefrontPage}
							checked={
								checklistState?.build?.editStoreFrontPage !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.PublishStorefront}
							checked={
								checklistState?.build?.publishStoreFront !==
								ChecklistStatus.NotVisited
							}
						/>
					</SetupStepDisclosure>
				</div>
				<div className="flex">
					<div className="mr-4 mt-3 flex min-h-full flex-col items-center">
						<Progress percentage={getPercentage(checklistState?.deploy)} />
					</div>
					<SetupStepDisclosure
						title="Deploy"
						description="Connect your domain and deploy the fastest, most reliable store there is"
						defaultOpen={selected === 'deploy'}
					>
						<Checkbox
							step={ChecklistStep.ConfigureLanguages}
							checked={
								checklistState?.deploy?.configureLanguages !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.SetupRedirects}
							checked={
								checklistState?.deploy?.setupRedirects !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.ConfigureHeadlessTheme}
							checked={
								checklistState?.deploy?.configureHeadlessTheme !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.FinalChecklist}
							checked={
								checklistState?.deploy?.finalCheck !==
								ChecklistStatus.NotVisited
							}
						/>
						<Checkbox
							step={ChecklistStep.Launch}
							checked={
								checklistState?.deploy?.launch !== ChecklistStatus.NotVisited
							}
						/>
					</SetupStepDisclosure>
				</div>
			</div>
			<div className="mt-5 mb-8 box-border flex items-center rounded-lg border border-solid border-gray-100 bg-white px-5 py-4 text-sm text-gray-700 shadow-sm">
				<Switch.Group as="div" className="flex items-center">
					<Switch
						checked={!onboardingFlowSkipped}
						onChange={(value) => skipOnboardingFlow(!value)}
						className={cx(
							!onboardingFlowSkipped ? `bg-gray-500` : 'bg-gray-200',
							'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
						)}
					>
						<span
							aria-hidden
							className={cx(
								!onboardingFlowSkipped ? 'translate-x-5' : 'translate-x-0',
								'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
							)}
						></span>
					</Switch>
					<Switch.Label
						as="span"
						className="ml-3 block w-full cursor-pointer text-sm leading-5 text-gray-900 "
					>
						Show guided store setup progress on store overview page
					</Switch.Label>
				</Switch.Group>
			</div>
		</div>
	);
};

export default function StoreGuidedSetup() {
	return (
		<>
			<StoreGuidedSetupChecklist />
		</>
	);
}
