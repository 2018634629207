import { ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

type TooltipProps = TooltipPrimitive.TooltipContentProps & {
	children: ReactNode;
	message: string;
};

export const Tooltip = ({ message, children, ...props }: TooltipProps) => {
	return (
		<TooltipPrimitive.Provider>
			<TooltipPrimitive.Root delayDuration={0}>
				<TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Content {...props} side={props.side ?? 'top'}>
					<p className="whitespace-normal rounded-lg bg-gray-900 py-2 px-3 text-xs font-medium leading-4 text-white shadow-lg">
						{message}
					</p>
				</TooltipPrimitive.Content>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
};

export { Tooltip as TooltipWithArrow } from './WithArrow';
