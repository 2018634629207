import { createContext, useContext } from 'react';
import { useParams } from 'react-router';

import {
	useUserWithOrganizationsQuery,
	CompleteUserFragment,
} from '~graphql/api/hooks';
import { useQueryWrapper } from '~hooks/useQueryWrapper';
import { Maybe } from '~types/global';

import { useToken } from './TokenProvider';

type UserContextProps = {
	isLoading: boolean;
	user: CompleteUserFragment | undefined;
	username: Maybe<string>;
};

export const UserContext = createContext<UserContextProps>(undefined as any);

export const UserProvider = ({ children }) => {
	const params = useParams();
	const {
		token,
		auth0User,
		isAuthenticated,
		isLoading: isLoadingToken,
	} = useToken();

	const {
		data,
		error,
		isLoading: isLoadingUser,
	} = useQueryWrapper(useUserWithOrganizationsQuery, {
		organization: params.organization!,
	})({}, { enabled: Boolean(token) });

	let isLoading = isLoadingToken;
	if (isAuthenticated && !isLoadingToken) {
		isLoading = isLoadingUser || (!data && !error);
	}

	let user;

	const username =
		data?.me?.firstName && data?.me?.lastName
			? `${data?.me.firstName} ${data?.me.lastName}`
			: data?.me?.name ?? data?.me?.email ?? auth0User?.name;

	if (Boolean(data?.me)) {
		user = { ...data?.me, ...auth0User, username };
	}

	return (
		<UserContext.Provider
			value={{
				user,
				isLoading: isLoading,
				username,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	const ctx = useContext(UserContext);

	if (!ctx) {
		throw new Error('[user] consumer needs to be wrapped a [user] provider');
	}

	return ctx;
};
