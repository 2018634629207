import { useQuery } from '@tanstack/react-query';

export function queryWrapper<
	Query extends (variables?: any, options?: any) => TReturn,
	TReturn extends ReturnType<typeof useQuery>,
	TVariables = Parameters<Query>[0],
	TOptions = Parameters<Query>[1],
>(
	query: (variables: TVariables, options?: TOptions) => TReturn,
	overrides?: { organization: string; store?: string },
): (variables?: TVariables, options?: TOptions) => TReturn {
	return (variables?: TVariables, options?: TOptions) =>
		query(
			{
				...overrides,
				...variables,
			} as TVariables & { organization?: string; store?: string },
			options,
		);
}
