import type { ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { ErrorFallback } from '~components/Errors';

export const Boundary = ({ children }: { children: ReactNode }) => (
	<Sentry.ErrorBoundary
		fallback={({ error, resetError }) => (
			<div className="flex flex-1 flex-col justify-center">
				<ErrorFallback error={error} resetErrorBoundary={resetError} />
			</div>
		)}
	>
		{children}
	</Sentry.ErrorBoundary>
);
