type ThunderstormProps = {
	className?: string;
};

export const Thunderstorm = ({ className = '' }: ThunderstormProps) => (
	<svg
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 362 241"
		className={className}
	>
		<circle cx={206.5} cy={51.5} r={34.5} fill="#C4C4C4" />
		<g filter="url(#a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M73.084 98.362A41.518 41.518 0 0 1 61.5 100C38.58 100 20 81.42 20 58.5S38.58 17 61.5 17c11.753 0 22.364 4.885 29.915 12.736A46.811 46.811 0 0 1 112 25c25.957 0 47 21.043 47 47s-21.043 47-47 47c-16.187 0-30.463-8.183-38.916-20.638Z"
				fill="url(#b)"
			/>
		</g>
		<g filter="url(#c)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M133.371 82.875C119.467 75.48 110 60.846 110 44c0-24.3 19.699-44 44-44 23.381 0 42.503 18.237 43.916 41.263C218.245 47.608 233 66.58 233 89c0 27.614-22.386 50-50 50s-50-22.386-50-50c0-2.074.126-4.118.371-6.125Z"
				fill="url(#d)"
			/>
		</g>
		<g filter="url(#e)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M300 111c23.196 0 42-18.804 42-42s-18.804-42-42-42c-17.403 0-32.335 10.585-38.707 25.669A34.66 34.66 0 0 0 254.5 52C235.446 52 220 67.446 220 86.5c0 19.054 15.446 34.5 34.5 34.5 11.351 0 21.422-5.482 27.709-13.943A41.841 41.841 0 0 0 300 111Z"
				fill="url(#f)"
			/>
		</g>
		<g filter="url(#g)">
			<path
				d="M115 146.044 191.563 49l-28.221 70.013L217 110.279 128.92 216l39.166-78.742L115 146.044Z"
				fill="#FFD101"
			/>
		</g>
		<g filter="url(#h)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M240.5 89c22.368 0 40.5-18.132 40.5-40.5C281 26.133 262.868 8 240.5 8a40.315 40.315 0 0 0-23.085 7.219A44.46 44.46 0 0 0 203.5 13C178.923 13 159 32.923 159 57.5s19.923 44.5 44.5 44.5c12.441 0 23.69-5.105 31.765-13.335 1.714.22 3.461.335 5.235.335Z"
				fill="url(#i)"
			/>
		</g>
		<defs>
			<filter
				id="a"
				x={0}
				y={17}
				width={179}
				height={142}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect1_dropShadow_2393_20908"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_20908"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect2_dropShadow_2393_20908"
				/>
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_20908"
					result="effect2_dropShadow_2393_20908"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_20908"
					result="shape"
				/>
			</filter>
			<filter
				id="c"
				x={90}
				y={0}
				width={163}
				height={179}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect1_dropShadow_2393_20908"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_20908"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect2_dropShadow_2393_20908"
				/>
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_20908"
					result="effect2_dropShadow_2393_20908"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_20908"
					result="shape"
				/>
			</filter>
			<filter
				id="e"
				x={200}
				y={27}
				width={162}
				height={134}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect1_dropShadow_2393_20908"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_20908"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect2_dropShadow_2393_20908"
				/>
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_20908"
					result="effect2_dropShadow_2393_20908"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_20908"
					result="shape"
				/>
			</filter>
			<filter
				id="g"
				x={100}
				y={44}
				width={132}
				height={197}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={4} />
				<feGaussianBlur stdDeviation={3} />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 0.819608 0 0 0 0 0.00392157 0 0 0 0.1 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_20908"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={7.5} />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 0.819608 0 0 0 0 0.00392157 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_20908"
					result="effect2_dropShadow_2393_20908"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_20908"
					result="shape"
				/>
			</filter>
			<filter
				id="h"
				x={139}
				y={8}
				width={162}
				height={134}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect1_dropShadow_2393_20908"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_20908"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius={5}
					in="SourceAlpha"
					result="effect2_dropShadow_2393_20908"
				/>
				<feOffset dy={20} />
				<feGaussianBlur stdDeviation={12.5} />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_20908"
					result="effect2_dropShadow_2393_20908"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_20908"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="b"
				x1={112}
				y1={25}
				x2={112}
				y2={119}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#E5E7EB" />
			</linearGradient>
			<linearGradient
				id="d"
				x1={183}
				y1={39}
				x2={183}
				y2={139}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#E5E7EB" />
			</linearGradient>
			<linearGradient
				id="f"
				x1={300}
				y1={27}
				x2={300}
				y2={111}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#E5E7EB" />
			</linearGradient>
			<linearGradient
				id="i"
				x1={240.5}
				y1={8}
				x2={240.5}
				y2={89}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#fff" />
				<stop offset={1} stopColor="#E5E7EB" />
			</linearGradient>
		</defs>
	</svg>
);
