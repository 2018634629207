// helper to extract error message from graphql errors with default fallback
type ExtractErrorMsg = {
	message: string;
	code?: string;
};

export const extractErrorMsg = (
	error: unknown,
	defaultMsg: string = 'An error has occurred.',
): ExtractErrorMsg => {
	let extracted: ExtractErrorMsg = { message: defaultMsg };

	if (error instanceof Error) {
		if (typeof (error as unknown as any).response !== 'undefined') {
			if (
				Array.isArray((error as unknown as any)?.response?.errors) &&
				typeof (error as unknown as any).response.errors[0]?.message ===
					'string'
			) {
				extracted.message = (
					error as unknown as any
				).response.errors[0]?.message;
				extracted.code =
					(error as unknown as any).response.errors[0]?.extensions?.response
						?.statusCode ?? (error as unknown as any)?.response?.status;
			}

			if (import.meta.env.DEV) {
				console.log(
					'error instanceof Error',
					(error as unknown as any).response,
					extracted,
				);
			}
		}
	}

	return extracted;
};
