import { useEffect } from 'react';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

import { Loader } from '~components';
import { AuthRoute } from '~routes';

export default function Login() {
	const [params] = useSearchParams();
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		const invitation = params.get('invitation');
		const organization = params.get('organization');

		let options: RedirectLoginOptions = {};
		if (invitation && organization) options = { invitation, organization };

		loginWithRedirect(options);
	}, []);

	return (
		<AuthRoute isProtected={false}>
			<Loader />
		</AuthRoute>
	);
}
