import { Button } from '~components/atoms';
import { Logo } from '~components/Logo';

import { ErrorFooter } from './atoms/Footer';

export const FailedToFetch = ({ handleReset }) => {
	const handleReload = () => {
		window.location.reload();
		handleReset();
	};

	return (
		<div className="fixed inset-0 z-[52] flex flex-1 flex-col">
			<div className="absolute inset-0 bg-gray-900 opacity-50" />
			<div className="z-[53] flex flex-1 flex-col items-center justify-center">
				<div className="flex flex-col items-center justify-center rounded-md bg-white p-6 shadow-md">
					<Logo className="mx-auto h-12 w-auto" />
					<h1 className="mt-6 text-sm font-black uppercase tracking-wide text-gray-600">
						application has been updated
					</h1>

					<p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
						new version available
					</p>

					<p className="mt-2 text-base text-gray-500">
						Please refresh to start using the latest version.
					</p>

					<Button
						type="button"
						onClick={handleReload}
						className="mt-6 mb-10 rounded-md bg-primary-500 py-2 px-4 text-sm font-medium text-gray-900 hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
					>
						Refresh page
					</Button>
					<ErrorFooter />
				</div>
			</div>
		</div>
	);
};
