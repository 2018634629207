export const Logo = ({ className = 'h-8 w-auto' }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 100 164"
	>
		<path
			d="M0 95.3003L75.0623 0L47.3943 68.7548L100 60.1782L13.6472 164L52.045 86.673L0 95.3003Z"
			fill="#FFD101"
		/>
	</svg>
);

export const TextLogo = ({ className = 'h-8 w-auto' }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 170 32"
		fill="none"
	>
		<path d="M0 22.1177H1.98216V10.5805H0V22.1177Z" fill="#4B5563" />
		<path
			d="M8.84892 13.6461C7.60393 13.6461 6.60467 14.3053 6.06408 15.3931V13.8768H4.19659V22.1177H6.06408V18.0797C6.06408 16.3985 6.9323 15.2778 8.17729 15.2778C9.61885 15.2778 9.61885 16.6128 9.61885 17.5522V22.1177H11.4863V17.5028C11.4863 16.2667 11.4863 13.6461 8.84892 13.6461Z"
			fill="#4B5563"
		/>
		<path
			d="M16.4159 22.3484C18.5455 22.3484 20.0362 21.376 20.0362 19.7113C20.0362 16.5798 14.745 17.8489 14.745 16.1843C14.745 15.5909 15.3675 15.1624 16.3176 15.1624C17.4479 15.1624 18.1851 15.8052 17.9557 16.7446H19.7741C20.1181 14.8822 18.5291 13.6461 16.334 13.6461C14.3518 13.6461 12.8283 14.6515 12.8283 16.2996C12.8283 19.3817 18.1032 18.195 18.1032 19.8432C18.1032 20.4365 17.4315 20.8321 16.4323 20.8321C15.1545 20.8321 14.401 20.1893 14.5156 19.1674H12.7137C12.5826 21.1617 14.1061 22.3484 16.4159 22.3484Z"
			fill="#4B5563"
		/>
		<path
			d="M26.3609 15.5085V13.8768H24.0184V11.6848H22.1509V13.8768H20.5947V15.5085H22.1509V19.53C22.1509 20.42 22.282 22.3484 24.7228 22.3484C25.3125 22.3484 25.8204 22.233 26.1807 22.0517V20.4036C25.8367 20.5849 25.5091 20.7002 25.0832 20.7002C24.1822 20.7002 24.0184 20.1893 24.0184 19.5136V15.5085H26.3609Z"
			fill="#4B5563"
		/>
		<path
			d="M31.0578 13.6461C28.9282 13.6461 27.3228 14.8657 27.7651 16.6952H29.649C29.4524 15.7722 30.0585 15.2778 31.0414 15.2778C32.352 15.2778 32.7942 16.1843 32.7942 17.4369C32.2209 17.305 31.5656 17.2226 30.9268 17.2226C28.617 17.2226 27.1754 18.228 27.1754 19.8926C27.1754 21.3265 28.2566 22.3484 29.993 22.3484C31.3363 22.3484 32.3356 21.7386 32.9417 20.6838V22.1177H34.6617V17.4369C34.6617 15.1459 33.4495 13.6461 31.0578 13.6461ZM30.3698 20.8486C29.5671 20.8486 29.0757 20.5025 29.0757 19.8432C29.0757 19.085 29.7309 18.5906 31.0742 18.5906C31.6639 18.5906 32.3192 18.6895 32.8598 18.8708C32.5485 20.0739 31.5656 20.8486 30.3698 20.8486Z"
			fill="#4B5563"
		/>
		<path
			d="M40.9559 13.6461C39.7109 13.6461 38.7117 14.3053 38.1711 15.3931V13.8768H36.3036V22.1177H38.1711V18.0797C38.1711 16.3985 39.0393 15.2778 40.2843 15.2778C41.7258 15.2778 41.7258 16.6128 41.7258 17.5522V22.1177H43.5933V17.5028C43.5933 16.2667 43.5933 13.6461 40.9559 13.6461Z"
			fill="#4B5563"
		/>
		<path
			d="M50.1812 15.5085V13.8768H47.8387V11.6848H45.9712V13.8768H44.415V15.5085H45.9712V19.53C45.9712 20.42 46.1023 22.3484 48.5431 22.3484C49.1328 22.3484 49.6407 22.233 50.001 22.0517V20.4036C49.657 20.5849 49.3294 20.7002 48.9035 20.7002C48.0025 20.7002 47.8387 20.1893 47.8387 19.5136V15.5085H50.1812Z"
			fill="#4B5563"
		/>
		<path
			d="M59.4616 18.5952L74.0485 0L68.6718 13.4156L78.8946 11.7421L62.1137 32L69.5756 16.9118L59.4616 18.5952Z"
			fill="#FCD34D"
		/>
		<path
			d="M92.8261 21.9197C95.7584 21.9197 97.8388 20.0902 98.2156 17.3872H96.1188C95.8075 19.0024 94.6117 20.1726 92.8425 20.1726C90.631 20.1726 89.0748 18.3926 89.0748 15.9039C89.0748 13.4975 90.5818 11.6681 92.8261 11.6681C94.7591 11.6681 95.955 12.9866 95.6437 14.9974H97.6914C98.1665 12.0966 95.9877 9.92101 92.8097 9.92101C89.4351 9.92101 87.0271 12.3768 87.0271 15.9039C87.0271 19.3485 89.3532 21.9197 92.8261 21.9197Z"
			fill="#4B5563"
		/>
		<path
			d="M103.421 21.9197C105.911 21.9197 107.598 20.1726 107.598 17.5685C107.598 14.9644 105.911 13.2173 103.421 13.2173C100.915 13.2173 99.2438 14.9644 99.2438 17.5685C99.2438 20.1726 100.915 21.9197 103.421 21.9197ZM103.421 20.2715C102.078 20.2715 101.193 19.2002 101.193 17.5685C101.193 15.9368 102.078 14.8655 103.421 14.8655C104.748 14.8655 105.649 15.9368 105.649 17.5685C105.649 19.2002 104.748 20.2715 103.421 20.2715Z"
			fill="#4B5563"
		/>
		<path
			d="M118.545 13.2173C117.316 13.2173 116.35 13.8766 115.875 14.9644C115.531 13.8931 114.663 13.2173 113.467 13.2173C112.205 13.2173 111.288 13.9425 110.862 14.915V13.4481H108.995V21.6889H110.862V17.6674C110.862 15.9533 111.698 14.849 112.795 14.849C113.696 14.849 114.139 15.5742 114.139 16.6455V21.6889H116.006V17.6509C116.006 15.9698 116.792 14.882 117.923 14.882C118.807 14.882 119.25 15.5907 119.25 16.6455V21.6889H121.117V16.4478C121.117 14.437 120.232 13.2173 118.545 13.2173Z"
			fill="#4B5563"
		/>
		<path
			d="M132.303 13.2173C131.074 13.2173 130.108 13.8766 129.633 14.9644C129.289 13.8931 128.421 13.2173 127.225 13.2173C125.963 13.2173 125.046 13.9425 124.62 14.915V13.4481H122.753V21.6889H124.62V17.6674C124.62 15.9533 125.456 14.849 126.553 14.849C127.454 14.849 127.896 15.5742 127.896 16.6455V21.6889H129.764V17.6509C129.764 15.9698 130.55 14.882 131.68 14.882C132.565 14.882 133.007 15.5907 133.007 16.6455V21.6889H134.875V16.4478C134.875 14.437 133.99 13.2173 132.303 13.2173Z"
			fill="#4B5563"
		/>
		<path
			d="M142.277 19.0848C141.998 19.843 141.277 20.2715 140.36 20.2715C139.05 20.2715 138.296 19.4639 138.132 18.0959H143.964C144.75 15.3929 143.26 13.2173 140.376 13.2173C137.788 13.2173 136.15 14.9479 136.15 17.5685C136.15 20.1891 137.772 21.9197 140.327 21.9197C142.375 21.9197 143.833 20.8154 144.308 19.0848H142.277ZM140.344 14.7996C141.638 14.7996 142.309 15.5742 142.195 16.7609H138.165C138.362 15.4918 139.115 14.7996 140.344 14.7996Z"
			fill="#4B5563"
		/>
		<path
			d="M149.821 13.2173C148.805 13.2173 148.019 13.8272 147.576 14.849V13.4481H145.709V21.6889H147.576V17.6509C147.576 15.9863 148.199 14.882 149.084 14.882C149.657 14.882 150.017 15.327 150.017 15.9863C150.017 16.3653 149.886 16.8103 149.608 17.2718H151.557C151.803 16.7774 151.95 16.184 151.95 15.5907C151.95 14.2062 151.115 13.2173 149.821 13.2173Z"
			fill="#4B5563"
		/>
		<path
			d="M156.853 21.9197C159.13 21.9197 160.506 20.4693 160.899 18.7552H158.884C158.54 19.6782 157.852 20.2715 156.869 20.2715C155.509 20.2715 154.625 19.2002 154.625 17.5685C154.625 15.9368 155.493 14.8655 156.902 14.8655C158.245 14.8655 158.917 15.8379 158.606 17.4696H160.489C161.096 14.9644 159.507 13.2173 156.918 13.2173C154.33 13.2173 152.675 14.9479 152.675 17.5685C152.675 20.1726 154.346 21.9197 156.853 21.9197Z"
			fill="#4B5563"
		/>
		<path
			d="M167.969 19.0848C167.69 19.843 166.969 20.2715 166.052 20.2715C164.742 20.2715 163.988 19.4639 163.824 18.0959H169.656C170.442 15.3929 168.952 13.2173 166.068 13.2173C163.48 13.2173 161.842 14.9479 161.842 17.5685C161.842 20.1891 163.464 21.9197 166.019 21.9197C168.067 21.9197 169.525 20.8154 170 19.0848H167.969ZM166.036 14.7996C167.33 14.7996 168.001 15.5742 167.887 16.7609H163.857C164.054 15.4918 164.807 14.7996 166.036 14.7996Z"
			fill="#4B5563"
		/>
	</svg>
);
