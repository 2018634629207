import { Outlet } from 'react-router-dom';

import { OrganizationProvider } from '~context';
import { OrganizationRoute } from '~routes';

export const OrganizationWrapper = () => (
	<OrganizationProvider>
		<OrganizationRoute>
			<Outlet />
		</OrganizationRoute>
	</OrganizationProvider>
);
