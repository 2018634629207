import { Suspense } from 'react';
import { FallBack } from '~components';
import { useLazy } from '~hooks/useLazy';
import { handlePromise } from '~lib/handlePromise';

const handleDynamicLoad = async (path: string) => {
	const [data, error] = await handlePromise(import(`./lazy/${path}/index.tsx`));

	if (!data || error) {
		console.warn(`failed to lazy load component for ${path}`);
		// always return an import function
		return import('./NotFound');
	}

	return data;
};

export const LazyContainer = ({ name }: { name: string }) => {
	const LazyComponent = useLazy(name, () => handleDynamicLoad(name));

	return (
		<Suspense fallback={<FallBack withBackground fillHeight />}>
			<LazyComponent />
		</Suspense>
	);
};

// root is the only container not lazy-loaded
export * from './Root';
// unauthenticated routes
export { default as Login } from './Login';
export { default as NotFound } from './NotFound';
