import { Button } from '~components/atoms';

import { FallingLightning } from '../assets/FallingLightning';

export const NotFound = () => (
	<div className="flex h-full w-full flex-row items-center justify-center bg-gray-50">
		<FallingLightning className="h-64 w-80" />
		<div className="ml-14 max-w-sm">
			<h1 className="mb-6 text-3xl font-black text-gray-900">
				We couldn't find the page you are looking for
			</h1>
			<p className="mb-6 max-w-xs text-left text-sm text-gray-500">
				We searched everywhere, but it seems our page doesn't exist anymore.
			</p>
			<Button className="mt-4" to="/">
				Back home
			</Button>
		</div>
	</div>
);
