import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NotFound } from '~components';
import { Root, LazyContainer } from '~containers';
import {
	baseRoutes,
	storeRoutes,
	organizationRoutes,
	unAuthenticatedRoutes,
} from '~routing';
import { AuthWrapper, OrganizationWrapper, StoreWrapper } from '~wrappers';

import '~styles.css';

const handledErrors = ['Failed to fetch dynamically imported module'];

if (
	import.meta.env.VITE_SENTRY_DSN &&
	import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE &&
	import.meta.env.VITE_RELEASE
) {
	Sentry.init({
		release: import.meta.env.VITE_RELEASE,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		beforeSend(event, hint) {
			if (hint.originalException instanceof Error) {
				const error = hint.originalException as Error;

				// check if we handle the error gracefully already
				if (
					handledErrors.some((message) => error.message.startsWith(message))
				) {
					return null;
				}
			}

			// prompt the report dialog with the eventId, potentially add more context here
			Sentry.showReportDialog({ eventId: event.event_id });
			return event;
		},
		tracesSampleRate: parseInt(import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE),
	});
}

const root = ReactDOMClient.createRoot(document.getElementById('root')!);

const renderComponent = (component: ReactNode | string) => {
	if (typeof component === 'string') {
		return <LazyContainer name={component} />;
	}

	return component;
};

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Root>
				<Routes>
					{unAuthenticatedRoutes.map(({ component, path }) => (
						<Route
							path={path}
							element={renderComponent(component)}
							key={path}
						/>
					))}

					<Route path="*" element={<AuthWrapper />}>
						{baseRoutes.map(({ component, path }) => (
							<Route
								path={path}
								element={renderComponent(component)}
								key={path}
							/>
						))}

						<Route path=":organization/*" element={<OrganizationWrapper />}>
							{organizationRoutes.map(({ component, path }) => (
								<Route
									path={path}
									element={renderComponent(component)}
									key={path}
								/>
							))}

							{/* STORE DETAIL ROUTES */}
							<Route path="stores/:store/*" element={<StoreWrapper />}>
								{storeRoutes.map(({ component, path }) => (
									<Route
										path={path}
										element={renderComponent(component)}
										key={path}
									/>
								))}

								{/* CATCH THE NOT FOUNDS FOR STORES HERE */}
								<Route path="*" element={<NotFound />} />
								{/* END STORE ROUTES */}
							</Route>

							{/* CATCH THE NOT FOUNDS FOR ORGANIZATIONS HERE */}
							<Route path="*" element={<NotFound />} />
							{/* END ORGANIZATION ROUTES */}
						</Route>

						{/* CATCH THE NOT FOUNDS FOR AUTHENTICATED ROUTES HERE */}
						<Route path="*" element={<NotFound />} />
						{/* END AUTH ROUTES */}
					</Route>

					{/* CATCH THE NOT FOUNDS FOR UNAUTHENTICATED ROUTES HERE */}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Root>
		</BrowserRouter>
	</React.StrictMode>,
);
