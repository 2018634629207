import { createContext, useContext } from 'react';
import { useParams } from 'react-router';

import { useOrganizationQuery, OrganizationQuery } from '~graphql/api/hooks';
import { queryWrapper } from '~lib/queryWrappers/query';

import { useUser } from './UserProvider';

type OrganizationContextProps = {
	organization: OrganizationQuery['currentOrganization'] | undefined;
	isLoading: boolean;
	isInTrial: boolean;
};

export const OrganizationContext = createContext<OrganizationContextProps>(
	undefined as any,
);
export const OrganizationProvider = ({ children }) => {
	const params = useParams();
	const { user } = useUser();
	const { data, isLoading } = queryWrapper(useOrganizationQuery, {
		organization: params.organization!,
	})(undefined, {
		enabled: Boolean(params?.organization) && Boolean(user?.id),
		retry: false,
	});
	const isInTrial = !!(
		data?.currentOrganization.numberOfTrialDaysLeft &&
		data?.currentOrganization.numberOfTrialDaysLeft > 0 &&
		!data.currentOrganization.stripeSubscriptionId
	);

	return (
		<OrganizationContext.Provider
			value={{
				isLoading,
				organization: data?.currentOrganization,
				isInTrial,
			}}
		>
			{children}
		</OrganizationContext.Provider>
	);
};

export const useOrganization = () => {
	return useContext(OrganizationContext);
};
