import { useParams } from 'react-router';

import { useUser } from '~context';

export enum RoleEnum {
	ADMIN = 'Admin',
	OWNER = 'Owner',
	EDITOR = 'Editor',
}

type UseAccessManagementProps = {
	rolesToEnforce: Array<RoleEnum>;
	force?: boolean;
};

export const useAccessManagement = ({
	rolesToEnforce,
}: UseAccessManagementProps) => {
	const { organization: organizationSlug } = useParams();
	const { user, isLoading } = useUser();
	/**
	 * returns a boolean or undefined.
	 * If undefined; hasn't fully loaded all logic
	 * If boolean; we can take action */
	let isAllowed: boolean | undefined;

	const organizationRoles = user?.organizations.find(
		({ slug }) => slug === organizationSlug,
	)?.roles;

	if (!isLoading && organizationRoles) {
		isAllowed = organizationRoles.some((role) =>
			rolesToEnforce?.some((allowedRole) => allowedRole === role.name),
		);
	}

	return {
		isAllowed,
	};
};
