import cx from 'classnames';
import { m, AnimatePresence } from 'framer-motion';
import { ChatDots } from 'phosphor-react';
import { Route, Routes, useLocation } from 'react-router';
import { getActiveState } from '~lib';

import { footerRoutes } from '~routing';

export const Footer = () => (
	<m.div
		initial={{ opacity: 0, y: 20 }}
		animate={{ opacity: 1, y: 0 }}
		exit={{ opacity: 0, y: 20 }}
		transition={{ stiffness: 0.8, duration: 0.2 }}
		className="content-container"
	>
		<div className="w-full bg-gray-100 pb-5">
			<div className="flex w-full justify-between border-t border-gray-300 pt-3 ">
				<div className="flex space-x-6">
					{[
						{ label: 'Support', href: import.meta.env.VITE_SUPPORT_URL },
						{
							label: 'Documentation',
							href: import.meta.env.VITE_DOCUMENTATION_URL,
						},
						{ label: 'Status', href: import.meta.env.VITE_STATUS_URL },
						{
							label: 'Terms & Conditions',
							href: import.meta.env.VITE_TERMS_AND_CONDITIONS_URL,
						},
						{
							label: 'Privacy Policy',
							href: import.meta.env.VITE_PRIVACY_POLICY_URL,
						},
					].map((item, index) => (
						<a
							key={item.label + item.href}
							target="_blank"
							rel="noreferrer"
							href={item.href}
							className={cx(
								'text-gray-500 text-xs leading-4 font-normal',
								index === 0 ? 'py-2 pr-4' : 'py-2 px-4',
							)}
						>
							{item.label}
						</a>
					))}
					<a
						target="_blank"
						rel="noreferrer noopener"
						href={import.meta.env.VITE_SEND_FEEDBACK_URL}
						className="flex py-2 px-4 text-xs font-normal leading-4 text-gray-500"
					>
						<ChatDots className="mr-1.5 h-4 w-4" />
						Send feedback
					</a>
				</div>
				<div className="py-2 pl-4 text-xs font-normal leading-4 text-gray-500">
					&copy; {new Date().getFullYear()} Instant Commerce
				</div>
			</div>
		</div>
	</m.div>
);

export const RouteAwareFooter = () => {
	const location = useLocation();

	const hasFooter = footerRoutes.find((route) =>
		getActiveState(route.path, location.pathname),
	);

	return (
		<AnimatePresence mode="wait" initial={false}>
			<Routes key={hasFooter ? 'with-footer' : 'hidden'} location={location}>
				{footerRoutes.map(({ fullPath }) => (
					<Route path={fullPath} element={<Footer />} key={fullPath} />
				))}

				{/** hide for those that don't match paths */}
				<Route path="*" element={null} />
			</Routes>
		</AnimatePresence>
	);
};
