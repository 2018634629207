import { Button } from '~components/atoms';

import { Thunderstorm } from '../assets/Thunderstorm';
import { ErrorFooter } from '../atoms/Footer';

type GeneralProps = {
	handleReset: () => void;
};

export const GeneralError = ({ handleReset }: GeneralProps) => (
	<div className="absolute inset-0 z-[51] flex flex-1 flex-col items-center justify-center bg-gray-50">
		<Thunderstorm className="h-56 w-80" />

		<div className="flex flex-col items-center pt-8 pb-16 text-center">
			<p className="text-sm font-black uppercase tracking-wide text-gray-600">
				unexpected error
			</p>
			<h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
				An error has occurred.
			</h1>
			<p className="mt-2 text-base text-gray-500">
				Our team has been notified, please reload the page to try again.
			</p>

			<Button type="button" onClick={handleReset} className="mt-6">
				Refresh page
			</Button>
		</div>
		<ErrorFooter />
	</div>
);
