import { Login } from '~containers';

type Route = {
	path: string;
	fullPath?: string; // if nested we should provide the full basepath
	component: JSX.Element | string; // should be a string for lazy loading later on
	parent?: 'organization' | 'store'; // indicated where the route is located
	layout?: 'fullscreen'; // defaults to sidebar and main view
	isUnprotected?: boolean; // optional flag to indicate when a route doesn't require authentication
	hasSidebar?: boolean;
	hasFooter?: boolean;
};

/**
 * the routing configuration
 * this configuration only covers the base routes
 * it's made so we can easily add more routes, have more control over the layout and even do fancy animations
 */
export const routing: Array<Route> = [
	/** unauthenticated routes */
	{
		path: 'login',
		fullPath: 'login',
		component: <Login />,
		isUnprotected: true,
		layout: 'fullscreen',
	},

	/** authenticated routes */
	{
		path: 'organizations/*',
		fullPath: 'organizations/*',
		component: 'Organizations',
		layout: 'fullscreen',
		hasFooter: false,
	},
	{
		path: 'account/*',
		fullPath: 'account/*',
		component: 'Account',
		layout: 'fullscreen',
		hasFooter: false,
	},
	{
		path: 'set-username',
		fullPath: 'set-username',
		component: 'SetUsername',
		layout: 'fullscreen',
		hasFooter: false,
	},

	/** organization routes */
	{
		path: 'stores',
		parent: 'organization',
		fullPath: ':organization/stores',
		component: 'OrganizationStores',
	},

	{
		path: 'trial-ended',
		parent: 'organization',
		fullPath: 'trial-ended',
		component: 'TrialEnded',
		layout: 'fullscreen',
		hasFooter: false,
	},

	{
		path: 'create-store/*',
		parent: 'organization',
		fullPath: ':organization/create-store/*',
		component: 'CreateStore',
		layout: 'fullscreen',
		hasFooter: false,
	},

	{
		path: 'manage/*',
		parent: 'organization',
		fullPath: ':organization/manage/*',
		component: 'OrganizationSettings',
		layout: 'fullscreen',
		hasFooter: false,
	},

	{
		path: 'team/*',
		parent: 'organization',
		fullPath: ':organization/team/*',
		component: 'OrganizationTeam',
	},

	{
		path: 'team/member/:memberId/*',
		parent: 'organization',
		fullPath: ':organization/team/member/:memberId/*',
		component: 'OrganizationMember',
	},

	{
		path: 'trial-ended',
		parent: 'organization',
		fullPath: ':organization/trial-ended',
		component: 'TrialEnded',
		layout: 'fullscreen',
		hasFooter: false,
	},

	/** store routes */
	{
		path: 'home',
		parent: 'store',
		fullPath: ':organization/stores/:store/home',
		component: 'StoreHome',
	},

	{
		path: 'settings/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/settings/*',
		component: 'StoreSettings',
	},

	{
		path: 'products/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/products/*',
		component: 'ProductOptions',
	},

	{
		path: 'integrations/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/integrations/*',
		component: 'Integrations',
	},

	{
		path: 'integrations-marketplace/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/integrations-marketplace/*',
		component: 'IntegrationsMarketplace',
	},

	{
		path: 'setup',
		parent: 'store',
		fullPath: ':organization/stores/:store/setup',
		component: 'StoreGuidedSetup',
	},

	// we explicitly set these as "integration/:detail" not "integrations/:detail"
	// this is done to avoid conflicts layouting (which can cause weird animation behavior at the moment)
	{
		path: 'integration/:integration/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/integration/:integration/*',
		component: 'IntegrationDetail',
		layout: 'fullscreen',
		hasFooter: false,
	},

	{
		path: 'custom-code/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/custom-code/*',
		component: 'CustomCode',
	},

	// we explicitly set these as "editor/:detail" not "custom-code/:detail"
	// this is done to avoid conflicts layouting (which can cause weird animation behavior at the moment)
	{
		path: 'editor/:markup/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/editor/:markup/*',
		component: 'Editor',
		layout: 'fullscreen',
		hasFooter: false,
	},
	{
		path: 'editor/:markup/:snippet/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/editor/:markup/:snippet/*',
		component: 'Editor',
		layout: 'fullscreen',
		hasFooter: false,
	},

	{
		path: 'customizer/*',
		parent: 'store',
		fullPath: ':organization/stores/:store/customizer/*',
		layout: 'fullscreen',
		component: 'Customizer',
		hasFooter: false,
	},
];

// ROUTE EXPORTS ~ LAYOUT BASED
export const footerRoutes = routing.filter(
	(route) => route.hasFooter !== false,
);

export const fullScreenRoutes = routing.filter(
	(route) => route.layout === 'fullscreen',
);

// ROUTE EXPORTS ~ UNAUTH
export const unAuthenticatedRoutes = routing.filter(
	(route) => route.isUnprotected,
);
// ROUTE EXPORTS ~ AUTH
export const baseRoutes = routing.filter((route) => !route.parent);
// ROUTE EXPORTS ~ AUTH + ORGANIZATION
export const organizationRoutes = routing.filter(
	(route) => route.parent === 'organization',
);
// ROUTE EXPORTS ~ AUTH + ORGANIZATION + STORE
export const storeRoutes = routing.filter((route) => route.parent === 'store');
