import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useOrganization } from '~context/OrganizationProvider';
import { useStore } from '~context/StoreProvider';

export function useQueryWrapper<
	Query extends (variables?: any, options?: any) => TReturn,
	TReturn extends ReturnType<typeof useQuery>,
	TVariables = Parameters<Query>[0],
	TOptions = Parameters<Query>[1],
>(
	hook: (variables: TVariables, options?: TOptions) => TReturn,
	overrides?: { organization?: string; store?: string },
): (variables?: TVariables, options?: TOptions) => TReturn {
	const organizationCtx = useOrganization();
	const storeCtx = useStore();

	const memoizedVariables = useMemo(
		() => ({
			organization:
				overrides?.organization ?? organizationCtx?.organization?.slug,
			store: overrides?.store ?? storeCtx?.store?.slug,
		}),
		[storeCtx?.store?.slug, organizationCtx?.organization?.slug, overrides],
	);

	return (variables?: TVariables, options?: TOptions) =>
		hook(
			{
				...memoizedVariables,
				...variables,
			} as TVariables & { organization?: string; store?: string },
			options,
		);
}
