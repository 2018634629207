import cx from 'classnames';
import { motion } from 'framer-motion';
import { startsWith } from 'lodash';
import { ArrowRight, ArrowSquareOut } from 'phosphor-react';
import { Link, LinkProps } from 'react-router-dom';

import { ChecklistStep } from '~graphql/api/hooks';
import { useGuidedStoreSetup } from '~hooks/useGuidedStoreSetup';

type CheckboxValuesType = {
	[name: string]: {
		label: string;
		to: string;
		docsUrl?: string;
		subTitle?: string;
		skipThisLabel?: string;
		canBeSkipped?: boolean;
	};
};

const LAUNCH_STORE_URL = `${
	import.meta.env.VITE_DOCUMENTATION_URL
}/product-documentation/guides/launch-your-store`;

const FINAL_CHECK_URL = `${
	import.meta.env.VITE_DOCUMENTATION_URL
}/product-documentation/guides/final-check`;

const CheckboxValues: CheckboxValuesType = {
	CREATE_ACCOUNT: {
		label: 'Create account',
		to: '',
	},
	[ChecklistStep.CreateStore]: {
		label: 'Create your first store',
		subTitle: '',
		to: '../../create-store/new/name',
	},
	[ChecklistStep.ConnectShopify]: {
		label: 'Connect Shopify ',
		subTitle:
			'Connect your Shopify store to fetch all your eCommerce related data.',
		to: '../integrations?tooltip=shopify',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/setting-up/connect-shopify`,
	},
	[ChecklistStep.ConnectStoryblok]: {
		label: 'Connect Storyblok',
		subTitle:
			'Connect the headless CMS Storyblok to create great content for your store.',
		to: '../integrations?tooltip=storyblok',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/setting-up/connect-storyblok`,
	},
	[ChecklistStep.InviteTeamMembers]: {
		label: 'Invite team members',
		subTitle: 'Build the store together with your team ',
		to: '../../manage/team',
		skipThisLabel: "I'm a one-man army. Skip this step",
		canBeSkipped: true,
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/your-organization/manage-your-team`,
	},
	[ChecklistStep.AddBrandAssets]: {
		label: 'Add logo and brand color',
		subTitle:
			'Start customizing your store by adding the essential brand assets.',
		canBeSkipped: true,
		to: '../customizer/pages/ProductDetailPage?sidebar=Styles&element=ThemeLogo',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/manage-your-storefront/visual-settings#styles`,
	},
	[ChecklistStep.AddStorefrontBlock]: {
		label: 'Create first section',
		subTitle:
			'Select and customize sections from the pre-built eCommerce section library.',
		canBeSkipped: true,
		to: '../customizer/sections',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/manage-your-storefront/blocks#add-sections`,
	},
	[ChecklistStep.EditStorefrontPage]: {
		label: 'Configure product pages',
		subTitle: 'Configure your Product Detail or Product Listing Page.',
		canBeSkipped: true,
		to: '../customizer/pages/ProductDetailPage',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/manage-your-storefront/blocks#pages`,
	},
	[ChecklistStep.PublishStorefront]: {
		label: 'Publish storefront',
		subTitle:
			'Push your customizations to the preview environment and start testing.',
		to: '../customizer/pages/ProductDetailPage?tooltip=publish',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/manage-your-storefront/working-with-changes#saving-and-publishing-changes`,
	},
	[ChecklistStep.ConfigureLanguages]: {
		label: 'Configure languages',
		subTitle:
			'Configure the necessary languages if you have a multilingual store.',
		skipThisLabel: 'I have a single language (EN) store. Skip this step',
		canBeSkipped: true,
		to: '../settings?tooltip=addLanguage',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/settings/languages`,
	},
	[ChecklistStep.SetupRedirects]: {
		label: 'Set up redirects',
		subTitle: 'Add relevant redirects to maintain your SEO ranking.',
		skipThisLabel: 'No redirects needed. Skip this step',
		canBeSkipped: true,
		to: '../settings/redirects',
		docsUrl: `${
			import.meta.env.VITE_DOCUMENTATION_URL
		}/product-documentation/settings/domains`,
	},
	[ChecklistStep.ConfigureHeadlessTheme]: {
		label: 'Configure Shopify headless theme',
		subTitle:
			'Upload our "headless theme" to redirect customers to your new store (after launch).',

		skipThisLabel: "I've done the configuration",
		canBeSkipped: true,
		to: LAUNCH_STORE_URL,
		docsUrl: LAUNCH_STORE_URL,
	},
	[ChecklistStep.FinalChecklist]: {
		label: 'Final check',
		subTitle:
			'Walkthrough the final critical checks before launching your store.',

		skipThisLabel: 'Everything is clear. Check off this step',
		canBeSkipped: true,
		to: FINAL_CHECK_URL,
		docsUrl: FINAL_CHECK_URL,
	},
	[ChecklistStep.Launch]: {
		label: 'Ready? Set.. Launch!',
		subTitle: "Connect domains and switch the DNS. Let's launch your store!",
		to: '../settings/domains?tooltip=addDomain',
	},
};

const CheckIcon = () => (
	<svg
		width="12"
		height="8"
		viewBox="0 0 12 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.3657 0.234315C11.6781 0.546734 11.6781 1.05327 11.3657 1.36569L4.96571 7.76569C4.65329 8.0781 4.14676 8.0781 3.83434 7.76569L0.634339 4.56569C0.32192 4.25327 0.32192 3.74673 0.634339 3.43431C0.946758 3.1219 1.45329 3.1219 1.76571 3.43431L4.40002 6.06863L10.2343 0.234315C10.5468 -0.0781049 11.0533 -0.0781049 11.3657 0.234315Z"
			fill="#FFD101"
		/>
	</svg>
);

type CheckboxLinkProps = {
	children?: JSX.Element | JSX.Element[] | string;
	checked?: boolean;
	to: string;
	linksExternal: boolean;
} & LinkProps;

const CheckboxLink = ({
	children,
	checked,
	to,
	className,
	linksExternal,
	...props
}: CheckboxLinkProps) => {
	if (checked) {
		return <div className={className}>{children}</div>;
	}

	if (linksExternal) {
		return (
			<a
				target="_blank"
				rel="noopener noreferrer"
				{...props}
				href={to}
				className={className}
			>
				{children}
			</a>
		);
	}

	return (
		<Link {...props} to={to} className={className}>
			{children}
		</Link>
	);
};

type CheckboxProps = {
	step: ChecklistStep | 'CREATE_ACCOUNT';
	checked: boolean;
};

export const Checkbox = ({ step, checked }: CheckboxProps) => {
	const {
		to,
		skipThisLabel = 'Skip this step',
		label,
		subTitle,
		canBeSkipped,
		docsUrl,
	} = CheckboxValues[step];
	const { skipChecklistStep, setChecklistStepDone } = useGuidedStoreSetup();
	const linksExternal = startsWith(to, 'https');

	return (
		<div
			className={cx(
				'flex',
				checked ? 'items-center cursor-default' : 'flex-col',
			)}
		>
			<motion.div
				whileHover="hover"
				className={cx(
					'group flex flex-col text-gray-500 hover:text-gray-600',
					!checked && '[&>a>div]:hover:border-gray-400',
				)}
			>
				<CheckboxLink
					checked={checked}
					to={to}
					className="flex"
					linksExternal={linksExternal}
				>
					<div
						className={cx(
							'flex items-center justify-center flex-shrink-0 h-6 w-6 rounded-full border border-gray-300 mr-4 bg-white',
							checked && 'border-primary-500',
						)}
					>
						{checked && <CheckIcon />}
					</div>
					<div
						className={cx(
							'flex gap-1 cursor-pointer text-base font-medium',
							!checked && 'text-gray-900',
							checked && 'line-through text-gray-400 cursor-default',
						)}
					>
						{label}
						{!canBeSkipped && !checked && (
							<span className="font-light">(required)</span>
						)}
						{!checked && (
							<motion.span
								variants={{
									hover: {
										marginLeft: '5px',
										transition: {
											duration: 0.3,
										},
									},
								}}
								className="flex items-center justify-center"
							>
								{linksExternal ? (
									<ArrowSquareOut className="w-4 text-primary-600" />
								) : (
									<ArrowRight className="w-4 text-primary-600" />
								)}
							</motion.span>
						)}
					</div>
				</CheckboxLink>
				{!checked && (
					<div className="mt-1 ml-10 text-sm font-normal leading-5">
						<CheckboxLink to={to} linksExternal={linksExternal}>
							{subTitle}
						</CheckboxLink>{' '}
						{docsUrl && (
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={docsUrl}
								className="text-sm text-gray-500 underline hover:text-gray-600"
							>
								Learn more
							</a>
						)}
					</div>
				)}
			</motion.div>
			{canBeSkipped && !checked && step !== 'CREATE_ACCOUNT' && (
				<button
					onClick={async () =>
						linksExternal
							? await setChecklistStepDone(step)
							: await skipChecklistStep(step)
					}
					className="mt-2 ml-10 flex text-xs text-secondary-600 hover:text-secondary-700"
				>
					{skipThisLabel}
				</button>
			)}
		</div>
	);
};
