import { useState } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { QueryClientConfig } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion';
import { IconContext } from 'phosphor-react';
import { Toaster } from 'react-hot-toast';

import { Boundary } from '~components';
import { TokenProvider } from '~context/TokenProvider';
import { UserProvider } from '~context/UserProvider';

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
	defaultOptions: {
		queries: {
			// 1000ms * 20 = 20s
			staleTime: 1000 * 20,
		},
	},
};

export const Root = ({ children }) => {
	const [queryClient] = useState(() => new QueryClient(QUERY_CLIENT_CONFIG));

	return (
		<Boundary>
			<QueryClientProvider client={queryClient}>
				<Auth0Provider
					redirectUri={window.location.origin}
					domain={import.meta.env.VITE_AUTH0_DOMAIN}
					clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
					audience={import.meta.env.VITE_AUTH0_AUDIENCE}
					cacheLocation={
						(window as any).Cypress || import.meta.env.DEV
							? 'localstorage'
							: 'memory'
					}
				>
					<TokenProvider>
						<UserProvider>
							<LazyMotion features={domAnimation}>
								<AnimatePresence mode="wait">
									<IconContext.Provider value={{ size: 20, weight: 'regular' }}>
										{children}
									</IconContext.Provider>
								</AnimatePresence>
							</LazyMotion>
						</UserProvider>
					</TokenProvider>
				</Auth0Provider>
				<ReactQueryDevtools position="bottom-right" />
			</QueryClientProvider>
			<Toaster />
		</Boundary>
	);
};
