import { Suspense } from 'react';

import { Outlet, useLocation } from 'react-router';

import { FallBack, RouteAwareFooter, RouteAwareSidebar } from '~components';
import { OrganizationProvider } from '~context';
import { getActiveState } from '~lib';
import { AuthRoute } from '~routes';
import { fullScreenRoutes } from '~routing';
import { Scrollable } from '~components/atoms';
import { RouteAwareUpgradeBar } from '~components/FreeTrial/UpgradeBar';
import { RouteAwareHeader } from '~components/Header/RouteAwareHeader';

export const LayoutWrapper = ({ children, isScrollable }) => {
	if (isScrollable) {
		return (
			<Scrollable>
				<main className="flex max-w-full flex-1 flex-col bg-gray-100 transition-all">
					{children}
				</main>
			</Scrollable>
		);
	}

	return (
		<main className="flex max-w-full flex-1 flex-col bg-gray-100 transition-all">
			{children}
		</main>
	);
};

export const AuthWrapper = () => {
	const location = useLocation();

	const isFullScreen = fullScreenRoutes.find((route) =>
		getActiveState(route.path, location.pathname),
	);

	return (
		<AuthRoute isProtected>
			<div className="flex h-full max-h-full w-full flex-col">
				<OrganizationProvider>
					<RouteAwareUpgradeBar />
				</OrganizationProvider>

				<RouteAwareHeader />

				<div className="relative flex h-full max-h-full overflow-hidden">
					<RouteAwareSidebar />

					<LayoutWrapper isScrollable={!isFullScreen}>
						<Suspense fallback={<FallBack withBackground fillHeight />}>
							<Outlet />
						</Suspense>

						<RouteAwareFooter />
					</LayoutWrapper>
				</div>
			</div>
		</AuthRoute>
	);
};
