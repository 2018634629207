import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { Loader } from '~components';
import { useUser } from '~context';

import { useStore } from '~context/StoreProvider';

type StoreRouteProps = {
	children: ReactNode;
};

export const StoreRoute = ({ children }: StoreRouteProps) => {
	const { user } = useUser();
	const { store, isLoading } = useStore();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!Boolean(store) && !isLoading) {
			if (user && Boolean(user?.organizations?.length)) {
				const to =
					user?.organizations?.length > 1
						? '/organizations'
						: `/${user?.organizations?.[0].slug}/stores`;
				navigate(to, { replace: true });
			}
		}
	}, [isLoading, store]);

	useEffect(() => {
		if (
			location.pathname === `/${params.organization}/stores/${params.store}`
		) {
			navigate(`/${params.organization}/stores/${params.store}/home`, {
				replace: true,
			});
		}
	}, [location]);

	if (isLoading) return <Loader />;

	return <>{children}</>;
};
