import { lazy, useRef } from 'react';

export const useLazy = (name, callToImport) => {
	// refs will be used to remain relatively stable
	const lazyComponentsStore = useRef({}).current;

	if (!lazyComponentsStore[name]) {
		lazyComponentsStore[name] = lazy(callToImport);
	}

	return lazyComponentsStore[name];
};
