type FallingLightningProps = {
	className?: string;
};

export const FallingLightning = ({ className = '' }: FallingLightningProps) => (
	<svg
		viewBox="0 0 274 284"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M0 156.922V138.04l41.308-64.67h16.888v25.633h-9.798L24.224 137.06v.783h59.82v19.078H0Zm48.694 16.632V151.15l.492-8.268V73.37h22.796v100.184H48.694ZM135.057 176c-8.797 0-16.379-2.071-22.747-6.213-6.367-4.174-11.275-10.158-14.721-17.953-3.447-7.826-5.153-17.235-5.12-28.225.032-10.991 1.756-20.318 5.17-27.981 3.446-7.697 8.336-13.55 14.671-17.562C118.678 74.022 126.26 72 135.057 72s16.379 2.022 22.747 6.066c6.4 4.011 11.324 9.865 14.77 17.561 3.447 7.697 5.153 17.024 5.121 27.982 0 11.023-1.724 20.447-5.17 28.274-3.447 7.827-8.354 13.812-14.721 17.953-6.335 4.109-13.918 6.164-22.747 6.164Zm0-19.812c5.252 0 9.502-2.658 12.752-7.974 3.249-5.348 4.858-13.55 4.825-24.605 0-7.24-.739-13.208-2.216-17.904-1.477-4.729-3.528-8.251-6.154-10.567-2.626-2.315-5.695-3.473-9.207-3.473-5.219 0-9.437 2.625-12.654 7.876-3.216 5.218-4.841 13.24-4.874 24.068-.033 7.337.673 13.42 2.117 18.246 1.477 4.827 3.545 8.43 6.204 10.811 2.659 2.348 5.728 3.522 9.207 3.522ZM186.956 156.922V138.04l41.308-64.67h16.888v25.633h-9.798l-24.175 38.058v.783H271v19.078h-84.044Zm48.693 16.632V151.15l.493-8.268V73.37h22.795v100.184h-23.288Z"
			fill="#E5E7EB"
		/>
		<rect x={94} y={27} width={1} height={151} rx={0.5} fill="url(#a)" />
		<rect x={103} y={9} width={1} height={132} rx={0.5} fill="url(#b)" />
		<rect x={114} y={22} width={1} height={140} rx={0.5} fill="url(#c)" />
		<rect x={129} y={9} width={1} height={121} rx={0.5} fill="url(#d)" />
		<rect x={145} y={18} width={1} height={101} rx={0.5} fill="url(#e)" />
		<rect x={161} width={1} height={110} rx={0.5} fill="url(#f)" />
		<rect x={176} y={20} width={1} height={131} rx={0.5} fill="url(#g)" />
		<path fill="url(#h)" d="M0 72h274v186H0z" />
		<path fill="url(#i)" d="M0 72h274v186H0z" />
		<g clipPath="url(#j)">
			<g clipPath="url(#k)">
				<path
					d="M127 216c-54.7 0-99 9.4-99 21 0 10.4 35.4 19 82 20.7l20.7-41.7H127Z"
					fill="url(#l)"
				/>
				<path
					d="m153 216.7-34.3 41.2c2.8 0 5.5.1 8.4.1 54.7 0 99-9.4 99-21-.1-9.7-31.1-17.8-73.1-20.3Z"
					fill="url(#m)"
				/>
				<g filter="url(#n)">
					<path
						d="m131.3 187 28.2-70L83 214l53.1-8.8-5.4 10.8-20.7 41.7c2.8.1 5.7.2 8.6.2l34.4-41.2 32-38.5-53.7 8.8Z"
						fill="#FFD101"
					/>
				</g>
			</g>
		</g>
		<defs>
			<linearGradient
				id="a"
				x1={94.5}
				y1={27}
				x2={94.5}
				y2={178}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="b"
				x1={103.5}
				y1={9}
				x2={103.5}
				y2={141}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="c"
				x1={114.5}
				y1={22}
				x2={114.5}
				y2={162}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="d"
				x1={129.5}
				y1={9}
				x2={129.5}
				y2={130}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="e"
				x1={145.5}
				y1={18}
				x2={145.5}
				y2={119}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="f"
				x1={161.5}
				y1={0}
				x2={161.5}
				y2={110}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="g"
				x1={176.5}
				y1={20}
				x2={176.5}
				y2={151}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6B7280" stopOpacity={0} />
				<stop offset={1} stopColor="#6B7280" />
			</linearGradient>
			<linearGradient
				id="h"
				x1={137}
				y1={72}
				x2={137}
				y2={258}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9FAFB" stopOpacity={0} />
				<stop offset={1} stopColor="#F9FAFB" />
			</linearGradient>
			<linearGradient
				id="i"
				x1={137}
				y1={72}
				x2={137}
				y2={258}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9FAFB" stopOpacity={0} />
				<stop offset={1} stopColor="#F9FAFB" />
			</linearGradient>
			<linearGradient
				id="l"
				x1={79.368}
				y1={216}
				x2={79.368}
				y2={258}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#4B5563" />
				<stop offset={1} stopColor="#0F1216" />
			</linearGradient>
			<linearGradient
				id="m"
				x1={172.322}
				y1={216}
				x2={172.322}
				y2={258}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#4B5563" />
				<stop offset={1} stopColor="#0F1216" />
			</linearGradient>
			<clipPath id="j">
				<path fill="#fff" transform="translate(28 117)" d="M0 0h198v167H0z" />
			</clipPath>
			<clipPath id="k">
				<path fill="#fff" transform="translate(28 117)" d="M0 0h198.1v141H0z" />
			</clipPath>
			<filter
				id="n"
				x={68}
				y={112}
				width={132}
				height={170.9}
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={4} />
				<feGaussianBlur stdDeviation={3} />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 0.819608 0 0 0 0 0.00392157 0 0 0 0.05 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_2393_21035"
				/>
				<feColorMatrix
					in="SourceAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy={10} />
				<feGaussianBlur stdDeviation={7.5} />
				<feColorMatrix values="0 0 0 0 1 0 0 0 0 0.819608 0 0 0 0 0.00392157 0 0 0 0.1 0" />
				<feBlend
					in2="effect1_dropShadow_2393_21035"
					result="effect2_dropShadow_2393_21035"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect2_dropShadow_2393_21035"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
