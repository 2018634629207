import cx from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '~components/atoms/Tooltip';

type StatusBadgeProps = {
	status: 'success' | 'warning' | 'error' | 'default' | 'info';
	labelIcon?: boolean;
	label: string;
	hiddenLabel?: boolean;
	small?: boolean;
	className?: string;
};

const bgColor: Record<StatusBadgeProps['status'], string> = {
	default: 'bg-gray-100',
	success: 'bg-green-100',
	warning: 'bg-yellow-100',
	error: 'bg-red-100',
	info: 'bg-blue-100',
};
const textColor: Record<StatusBadgeProps['status'], string> = {
	default: 'text-gray-800',
	success: 'text-green-800',
	warning: 'text-yellow-800',
	error: 'text-red-800',
	info: 'text-blue-800',
};
const iconColor: Record<StatusBadgeProps['status'], string> = {
	default: 'bg-gray-400',
	success: 'bg-green-400',
	warning: 'bg-yellow-400',
	error: 'bg-red-400',
	info: 'bg-blue-400',
};

export const StatusBadge = ({ small = false, ...props }: StatusBadgeProps) => {
	const content = (
		<span
			className={twMerge(
				bgColor[props.status],
				textColor[props.status],
				'inline-flex items-center justify-center rounded-full text-sm font-medium capitalize',
				!props?.hiddenLabel ? 'ml-2 px-3 py-0.5' : 'h-4 w-4',
				small && 'text-xs leading-4 font-medium',
				props?.className,
			)}
		>
			{props?.labelIcon && (
				<span
					className={cx(
						iconColor[props.status],
						'rounded-full',
						!props?.hiddenLabel ? '-ml-1 mr-1.5 h-2 w-2' : 'h-1.5 w-1.5',
					)}
				/>
			)}
			{!props?.hiddenLabel && props?.label}
		</span>
	);

	if (props?.hiddenLabel) {
		return (
			<Tooltip
				side="bottom"
				align="center"
				sideOffset={12}
				message={props?.label}
			>
				{content}
			</Tooltip>
		);
	}

	return content;
};
