import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { Loader } from '~components';
import { useUser } from '~context/UserProvider';

type AuthRouteProps = {
	isProtected: boolean;
	redirectTo?: string;
	children: ReactNode;
};

const redirectRoutes = ['/login', '/organizations', '/set-username'];

export const AuthRoute = ({
	children,
	isProtected,
	redirectTo = isProtected ? '/login' : '/',
}: AuthRouteProps) => {
	const location = useLocation();
	const { user, isLoading } = useUser();

	if (isLoading) return <Loader />;

	if (
		user &&
		!Boolean(user.organizations?.length) &&
		!redirectRoutes.includes(location.pathname)
	) {
		return <Navigate to="/organizations" />;
	}

	if (
		user &&
		(!user?.firstName || !user?.lastName) &&
		!redirectRoutes.includes(location.pathname)
	) {
		return <Navigate to="/set-username" />;
	}

	if (
		user &&
		Boolean(user.organizations?.length) &&
		location.pathname === '/'
	) {
		return (
			<Navigate
				to={
					user.organizations?.length > 1
						? `/organizations`
						: `/${user.organizations[0].slug}/stores`
				}
			/>
		);
	}

	if (!isProtected && Boolean(user) && location.pathname !== redirectTo) {
		return <Navigate to={redirectTo} />;
	}

	if (isProtected && !Boolean(user) && location.pathname !== redirectTo) {
		return <Navigate to={redirectTo} />;
	}

	return <>{children}</>;
};
