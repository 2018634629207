import cx from 'classnames';

import { Spinner } from '~components';

type FallBackProps = {
	className?: string;
	fillHeight?: boolean;
	withBackground?: boolean;
};

export const FallBack = ({
	className = 'flex justify-center items-center',
	withBackground = false,
	fillHeight = false,
}: FallBackProps) => (
	<div
		className={cx(
			className,
			withBackground && 'bg-gray-100',
			fillHeight ? 'flex-1' : 'h-64',
		)}
	>
		<Spinner className="h-10 w-10 !text-black" />
	</div>
);
