/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { GraphQLClient } from 'graphql-request';
import { useParams } from 'react-router';

import { useOrganization } from '~context/OrganizationProvider';
import { useStore } from '~context/StoreProvider';
import { useToken } from '~context/TokenProvider';

const getHeaders = (
	token: string | undefined = '',
	organization: string | undefined = '',
	storeId: string | undefined = '',
) => ({
	'x-instant-client': 'Dashboard',
	...(token && {
		'x-instant-access-token': token,
	}),
	...(organization && {
		'x-instant-organization': organization,
	}),
	...(storeId && {
		'x-instant-store-id': storeId,
	}),
});

export const getClient = (
	token: string | undefined = '',
	organizationId: string | undefined = '',
	storeId: string | undefined = '',
) => {
	return new GraphQLClient(`${import.meta.env.VITE_API_URL}/graphql`, {
		headers: getHeaders(token, organizationId, storeId),
	});
};

type EnhancedQueryVariables = {
	organization?: string;
	store?: string;
};
export const fetcher = <TData, TVariables>(
	query: string,
): ((variables?: TVariables & EnhancedQueryVariables) => Promise<TData>) => {
	const organizationCtx = useOrganization();
	const { token } = useToken();
	const storeCtx = useStore();
	const params = useParams();

	const headers = getHeaders(
		token,
		params?.organization ?? organizationCtx?.organization?.slug,
		storeCtx?.store?.id,
	);

	const client = useMemo(
		() =>
			new GraphQLClient(`${import.meta.env.VITE_API_URL}/graphql`, {
				headers,
			}),
		[headers],
	);

	return async (variables?: TVariables & EnhancedQueryVariables) => {
		const { organization, store, ...props } = variables ?? {};

		return client.request(query, props);
	};
};
