import { ReactNode } from 'react';

import * as ScrollArea from '@radix-ui/react-scroll-area';
import cx from 'classnames';

import * as styles from './scrollable.css';

export * from './HorizontalSwiper';

type ScrollableProps = {
	children: ReactNode;
	fullHeight?: boolean;
	lightTheme?: boolean;
	scrollbarClassName?: string;
	scrollAreaAsChild?: boolean;
	ref?: React.RefObject<any> | null;
};

export const Scrollable = ({
	children,
	fullHeight = true,
	lightTheme = false,
	scrollbarClassName = '',
}: ScrollableProps) => (
	<ScrollArea.Root
		type="hover"
		className={cx('max-h-full w-full flex-1', fullHeight ? 'h-full' : 'h-auto')}
	>
		<ScrollArea.Viewport
			className={cx(
				'!flex max-h-full max-w-full w-full flex-col',
				fullHeight ? 'h-full' : 'h-auto',
			)}
		>
			{children}
		</ScrollArea.Viewport>
		<ScrollArea.Scrollbar
			orientation="vertical"
			className={cx(
				styles.className,
				lightTheme ? 'bg-none' : 'bg-gray-300',
				scrollbarClassName,
				'z-50',
			)}
		>
			<ScrollArea.Thumb
				className={cx(
					'flex-1 rounded-full transition-colors',
					lightTheme ? 'bg-gray-300' : 'bg-gray-500',
					scrollbarClassName,
				)}
			/>
		</ScrollArea.Scrollbar>
		<ScrollArea.Scrollbar
			orientation="horizontal"
			className={cx(
				'h-1 mb-2 mx-4',
				lightTheme ? 'bg-none' : 'bg-gray-300',
				scrollbarClassName,
			)}
		>
			<ScrollArea.Thumb
				className={cx(
					'flex-1 rounded-full transition-colors w-3 h-4 cursor-pointer',
					lightTheme ? 'bg-gray-300' : 'bg-gray-500',
					scrollbarClassName,
				)}
			/>
		</ScrollArea.Scrollbar>
		<ScrollArea.Corner />
	</ScrollArea.Root>
);
