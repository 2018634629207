import React, { LegacyRef, ReactNode } from 'react';

import { m } from 'framer-motion';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '~components/Spinner';

export enum ButtonVariant {
	OUTLINE = 'OUTLINE',
	SOLID = 'SOLID',
	UNSTYLED = 'UNSTYLED',
	WARNING = 'WARNING',
}

export enum ButtonSize {
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
}

type ElementProps =
	| React.DetailedHTMLProps<
			React.ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
	  >
	| React.DetailedHTMLProps<
			React.AnchorHTMLAttributes<HTMLAnchorElement>,
			HTMLAnchorElement
	  >
	| LinkProps;

type CustomButtonProps = {
	children: ReactNode;
	size?: ButtonSize;
	className?: string;
	isLoading?: boolean;
	loadingLabel?: string;
	variant?: ButtonVariant;
};

type ComponentProps = CustomButtonProps & ElementProps;

const Loading = ({ label }) => (
	<m.div
		className="absolute flex items-center justify-center text-gray-900"
		initial={{ opacity: 0 }}
		animate={{ opacity: 1 }}
		exit={{ opacity: 0 }}
	>
		<Spinner className="h-5 w-5" />
		{label}
	</m.div>
);

const variants = {
	[ButtonVariant.UNSTYLED]: '',
	[ButtonVariant.OUTLINE]:
		'border shadow-sm leading-5 bg-white text-gray-700 border-gray-300 hover:bg-gray-50',
	[ButtonVariant.WARNING]:
		'text-white border border-red-600 bg-red-600 hover:bg-red-700 focus:ring-red-500',
	[ButtonVariant.SOLID]:
		'border border-brand text-gray-900 leading-5 shadow-sm bg-brand hover:bg-primary-400 hover:border-primary-400',
};

const sizes = {
	[ButtonSize.SMALL]: 'py-2 px-3 text-xs leading-4 font-medium',
	[ButtonSize.MEDIUM]: 'py-2 px-4 font-medium text-sm',
	[ButtonSize.LARGE]: 'py-2.5 px-4 text-base leading-6 font-medium',
};

export const Button = React.forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	ComponentProps
>(
	(
		{
			children,
			className,
			variant = ButtonVariant.SOLID,
			size = ButtonSize.MEDIUM,
			isLoading = false,
			loadingLabel,
			...buttonProps
		},
		ref,
	) => {
		const classNames = twMerge(
			variant !== ButtonVariant.UNSTYLED &&
				'flex justify-center focus-brand-secondary focus:ring-4 rounded-[4px] whitespace-nowrap transition-all',
			isLoading
				? variant !== ButtonVariant.UNSTYLED
					? `bg-gray-200 border border-gray-200 cursor-not-allowed text-transparent ${
							Boolean(loadingLabel) && 'px-6'
					  }`
					: `cursor-not-allowed text-transparent`
				: variants[variant],
			sizes[size],
			className,
		);

		if ('href' in buttonProps) {
			return (
				<a
					{...buttonProps}
					target="_blank"
					className={classNames}
					rel="noopener noreferrer"
					ref={ref as LegacyRef<HTMLAnchorElement>}
				>
					{isLoading && <Loading label={loadingLabel} />}
					{children}
				</a>
			);
		}

		if ('to' in buttonProps) {
			return (
				<Link {...buttonProps} className={classNames}>
					{isLoading && <Loading label={loadingLabel} />}
					{children}
				</Link>
			);
		}

		buttonProps = buttonProps as React.DetailedHTMLProps<
			React.ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>;

		return (
			<button
				{...buttonProps}
				className={classNames}
				ref={ref as LegacyRef<HTMLButtonElement>}
			>
				{isLoading && <Loading label={loadingLabel} />}
				{children}
			</button>
		);
	},
);
