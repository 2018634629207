import { createContext, useContext } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

type TokenContextProps = {
	isAuthenticated: boolean;
	token: string | undefined;
	isLoading: boolean;
	auth0User?: User;
};

export const TokenContext = createContext<TokenContextProps>(undefined as any);

export const TokenProvider = ({ children }) => {
	const {
		isAuthenticated,
		getAccessTokenSilently,
		getAccessTokenWithPopup,
		isLoading: isLoadingAuth,
		user: auth0User,
	} = useAuth0();

	invariant(
		import.meta.env.VITE_AUTH0_AUDIENCE,
		'VITE_AUTH0_AUDIENCE is required',
	);

	const getAccessToken = () =>
		getAccessTokenSilently({
			audience: import.meta.env.VITE_AUTH0_AUDIENCE,
		}).catch(() => {
			console.log('Failed to get access token');
			return getAccessTokenWithPopup();
		});

	const { data: token, error } = useQuery(['accessToken'], getAccessToken, {
		enabled: isAuthenticated,
	});

	return (
		<TokenContext.Provider
			value={{
				token,
				isAuthenticated,
				auth0User,
				isLoading: !isAuthenticated ? isLoadingAuth : !Boolean(token || error),
			}}
		>
			{children}
		</TokenContext.Provider>
	);
};

export const useToken = () => {
	const ctx = useContext(TokenContext);

	if (!ctx) {
		throw new Error('[token] consumer needs to be wrapped a [token] provider');
	}

	return ctx;
};
