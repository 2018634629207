import { differenceInCalendarDays, addDays } from 'date-fns';
import { AnimatePresence } from 'framer-motion';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useOrganization } from '~context';
import { organizationRoutes, storeRoutes } from '~routing';

export const RouteAwareUpgradeBar = () => {
	const location = useLocation();
	const organization = useOrganization();

	if (!organization || !organization.isInTrial) {
		return null;
	}

	const routesWithUpgradeBar = [...organizationRoutes, ...storeRoutes].filter(
		(route) =>
			!['customizer/*', 'manage/*', 'trial-ended'].includes(route.path),
	);

	return (
		<AnimatePresence initial={true}>
			<Routes location={location}>
				{routesWithUpgradeBar.map((route) => (
					<Route
						key={route.fullPath}
						path={route.fullPath}
						element={<UpgradeBar organization={organization} />}
					/>
				))}
			</Routes>
		</AnimatePresence>
	);
};

const UpgradeBar = ({ organization }) => (
	<div className="flex h-6 w-full items-center justify-center bg-gray-800 text-xs text-white">
		<div>
			{differenceInCalendarDays(
				addDays(new Date(), organization.organization.numberOfTrialDaysLeft),
				new Date(),
			)}{' '}
			days left in your trial
			<Link
				to={`../${organization.organization.slug}/manage/billing`}
				className="pl-1 text-primary-500"
			>
				Upgrade
			</Link>
		</div>
	</div>
);
