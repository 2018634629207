import { m } from 'framer-motion';
import { Logo } from '~components/Logo';

export const Loader = () => (
	<m.div
		initial={{ opacity: 1 }}
		animate={{ opacity: 1 }}
		exit={{ opacity: 0 }}
		className="flex flex-1"
	>
		<div className="flex flex-1 flex-col items-center justify-center">
			<Logo className="mx-auto h-40 animate-loader-pulse" />
		</div>
	</m.div>
);
