import { Outlet } from 'react-router-dom';

import { StoreProvider } from '~context';
import { StoreRoute } from '~routes';

export const StoreWrapper = () => (
	<StoreProvider>
		<StoreRoute>
			<Outlet />
		</StoreRoute>
	</StoreProvider>
);
