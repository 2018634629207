import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { AnimatePresence, m } from 'framer-motion';
import {
	ArrowSquareOut,
	CaretDown,
	PaintBrush,
	Plus,
	Storefront,
} from 'phosphor-react';
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { TextLogo, Logo, StoreFrontStatusBadge } from '~components';
import {
	OrganizationProvider,
	StoreContext,
	StoreProvider,
	useOrganization,
} from '~context';
import { useQueryWrapper } from '~hooks';
import { getActiveState } from '~lib';
import { fullScreenRoutes } from '~routing';
import { ButtonSize, Scrollable, Tooltip } from '~components/atoms';
import { Button, ButtonVariant } from '~components/atoms';
import { useStoresQuery } from '~graphql/api/hooks';

export const RouteAwareHeader = () => {
	const location = useLocation();

	const isFullScreen = fullScreenRoutes.find((route) =>
		getActiveState(route.path, location.pathname),
	);

	return (
		<AnimatePresence mode="wait" initial={false}>
			<Routes
				// this key should be composed of wether or not the header is hidden
				key={isFullScreen ? 'fullscreen' : 'header'}
				location={location}
			>
				{fullScreenRoutes.map(({ fullPath }) => (
					<Route path={fullPath} element={null} key={fullPath} />
				))}

				<Route path="*" element={<BaseHeader />} />
			</Routes>
		</AnimatePresence>
	);
};

export const BaseHeader = () => {
	const location = useLocation();
	// these checks should be for the animatedLinks
	const params = useParams();
	const isRoot =
		location.pathname === '/' || typeof params.store === 'undefined';

	return (
		<m.div
			layoutId="header"
			exit={{ opacity: 0, display: 'none' }}
			animate={{ opacity: 1, display: 'flex' }}
			initial={{ opacity: 0, display: 'none' }}
			transition={{ stiffness: 0.8, duration: 0.2 }}
			className="z-50 bg-white text-gray-50 shadow"
		>
			<AnimatePresence mode="wait" initial={false}>
				<Routes
					// this key should be composed of wether or not the sidebar is hidden
					key={isRoot ? 'organization' : 'store'}
					location={location}
				>
					<Route path=":organization/*" element={<OrganizationHeader />} />
					<Route
						path=":organization/stores/:store/*"
						element={<StoreHeader />}
					/>
				</Routes>
			</AnimatePresence>
		</m.div>
	);
};

export const OrganizationHeader = () => {
	return (
		<m.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="flex items-center justify-between px-5 py-3"
		>
			<TextLogo />
			{/** @TODO add site wide search and notification-center */}
		</m.div>
	);
};

const StoreListItem = ({ store }) => {
	const { organization } = useParams();
	return (
		<Link
			to={`/${organization}/stores/${store?.slug}/home`}
			className="flex w-[384px] cursor-pointer flex-col rounded-[4px] p-2 hover:bg-secondary-100"
		>
			<h3 className="text-sm font-bold leading-5 text-gray-900">
				{store?.name}
			</h3>
			<p className="mt-1 text-xs font-normal leading-4 text-gray-400">
				Last updated {formatDistanceToNow(parseISO(store?.updatedAt))} ago
			</p>
		</Link>
	);
};

const StoreList = () => {
	const { isLoading, data } = useQueryWrapper(useStoresQuery)({
		// @TODO: Add pagination at some point
		paging: {
			first: 50,
		},
	});

	if (isLoading) {
		return (
			<div className="flex flex-col space-y-2 px-2">
				{Array.from({ length: 4 }).map((_, i) => (
					<div
						className="h-11 w-[384px] animate-pulse rounded-[4px] bg-slate-200"
						key={`animated_loading-state_${i}`}
					/>
				))}
			</div>
		);
	}

	return (
		<div
			className={twMerge(
				'h-full max-h-[390px] overflow-hidden',
				data?.stores?.edges && data?.stores?.edges?.length > 6 && 'h-[390px]',
			)}
		>
			<Scrollable>
				<div className="flex flex-col space-y-1 px-2 py-3">
					{data?.stores?.edges?.map(({ node }) => (
						<StoreListItem store={node} key={node.id} />
					))}
				</div>
			</Scrollable>
		</div>
	);
};

const StoreSelect = ({ store }) => {
	const org = useOrganization();
	const location = useLocation();

	return (
		<DropdownMenu.Root key={location.pathname}>
			<DropdownMenu.Trigger asChild>
				<Button
					variant={ButtonVariant.UNSTYLED}
					className="group flex items-center rounded-[4px] px-2 py-1.5 text-sm font-bold leading-5 text-gray-800 hover:bg-gray-100 hover:text-gray-700 data-[state=open]:bg-gray-100 data-[state=open]:text-gray-700 [&_svg]:data-[state=open]:rotate-180 [&_svg]:data-[state=open]:text-gray-500"
				>
					{store?.name}
					<CaretDown
						width="bold"
						className="ml-2 h-3 w-3 text-gray-600 transition-transform duration-[.20s] ease-in-out group-hover:text-gray-500"
					/>
				</Button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					side="bottom"
					align="start"
					sideOffset={4}
					className="flex flex-col rounded-lg border border-gray-100 bg-white shadow"
				>
					<div className="flex items-center justify-between p-3">
						<h2 className="text-sm font-medium leading-5 text-gray-900">
							{org?.organization?.name}
						</h2>
						<Button
							variant={ButtonVariant.UNSTYLED}
							to={`/${org?.organization?.slug}/create-store/`}
							state={{ from: location.pathname }}
							className="flex items-center text-xs font-medium leading-4 text-secondary-500 hover:text-secondary-700"
						>
							<Plus className="mr-1 h-3 w-3" />
							Add store
						</Button>
					</div>
					<div className="border-y border-gray-200">
						<StoreList />
					</div>
					<div className="flex justify-center p-3">
						<Button
							to={`/${org?.organization?.slug}/stores`}
							variant={ButtonVariant.OUTLINE}
						>
							<Storefront className="mr-2" /> All storefronts
						</Button>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export const StoreHeader = () => {
	const params = useParams();
	const location = useLocation();

	return (
		<OrganizationProvider>
			<StoreProvider>
				<StoreContext.Consumer>
					{({ store, previewUrl }) => (
						<m.div
							transition={{ stiffness: 0.8, duration: 0.2 }}
							className="flex h-14 w-full items-center justify-between px-5 py-3"
						>
							<div className="flex">
								<Button
									to="/"
									variant={ButtonVariant.UNSTYLED}
									className="mr-2 border-r border-gray-200 p-0 pr-2"
								>
									<Logo />
								</Button>
								<StoreSelect store={store} />
							</div>

							<m.header className="z-10 flex justify-between transition-all duration-300">
								<div className="flex items-center space-x-3">
									<div className="border-r border-gray-200 pr-3">
										<Tooltip
											message="View preview"
											side="bottom"
											sideOffset={4}
											align="center"
										>
											<a
												target="_blank"
												href={previewUrl}
												rel="noopener noreferrer"
												className="relative flex h-8 w-8 items-center justify-center rounded-[4px] border border-gray-300 bg-white p-1.5 text-base leading-6 text-gray-700 shadow-sm transition-colors hover:bg-gray-50"
											>
												<ArrowSquareOut className="h-5 w-5" />
											</a>
										</Tooltip>
									</div>
									<StoreFrontStatusBadge store={store} hiddenLabel />
									<Button
										to={`/${params.organization}/stores/${params.store}/customizer/sections`}
										state={{ from: location.pathname }}
										size={ButtonSize.SMALL}
									>
										<PaintBrush className="mr-2 h-4 w-4" /> Customize
									</Button>
								</div>
							</m.header>
						</m.div>
					)}
				</StoreContext.Consumer>
			</StoreProvider>
		</OrganizationProvider>
	);
};
