import { useMemo } from 'react';
import { useOrganization, useStore } from '~context';

export function useQueryKeyWrapper<
	Query extends (variables?: any) => TReturn,
	TReturn extends Array<any>,
	TVariables = Parameters<Query>[0],
>(
	getKey: (variables: TVariables) => TReturn,
	overrides?: { organization?: string; store?: string },
): (variables?: TVariables) => TReturn {
	const organizationCtx = useOrganization();
	const storeCtx = useStore();

	const memoizedVariables = useMemo(
		() => ({
			organization:
				overrides?.organization ?? organizationCtx?.organization?.slug,
			store: overrides?.store ?? storeCtx?.store?.slug,
		}),
		[storeCtx?.store?.slug, organizationCtx?.organization?.slug, overrides],
	);

	return (variables?: TVariables) =>
		getKey({
			...memoizedVariables,
			...variables,
		} as TVariables & { organization?: string; store?: string });
}
