import { useEffect, useState } from 'react';
import cx from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { CaretDown } from 'phosphor-react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { getActiveState } from '~lib';

const AnimatedCaret = m(CaretDown);

export const AnimatedLink = (props) => {
	const { pathname } = useLocation();
	const [isShown, setIsShown] = useState(false);

	useEffect(() => {
		// sync state with pathname
		if (!isShown && props.isActive) {
			setIsShown(true);
		}
	}, [props.isActive]);

	if (props.items) {
		return (
			<div className="relative w-full">
				<button
					onClick={() => setIsShown((prev) => !prev)}
					className="flex w-full items-center rounded-md p-2 text-xs font-medium text-white transition-colors hover:bg-gray-800 hover:text-white"
				>
					<div className="mr-3 h-6 w-6 overflow-visible">
						{props?.icon && (
							<props.icon
								className={cx(
									'text-white h-6 w-6',
									props.isActive && 'text-primary-300',
								)}
							/>
						)}
					</div>
					<div className="flex w-full items-center justify-between">
						{props.label}
						<AnimatedCaret
							initial={false}
							animate={{ rotate: isShown ? 0 : -90 }}
							transition={{ stiffness: 0.8, duration: 0.2 }}
							className="h-4 w-4"
						/>
					</div>
				</button>
				<AnimatePresence mode="wait" initial={false}>
					{isShown && (
						<m.div
							className="my-1 space-y-1 overflow-hidden"
							initial={{ height: 0, opacity: 0 }}
							animate={{ height: 'auto', opacity: 1 }}
							exit={{ height: 0, opacity: 0 }}
						>
							{props.items?.map((item) => (
								<AnimatedLink
									{...item}
									isActive={pathname.endsWith(item?.href)}
									key={item.href}
									isNested
								/>
							))}
						</m.div>
					)}
				</AnimatePresence>
			</div>
		);
	}

	let LinkWrapper = ({ children, className }) => (
		<Link
			to={props.href}
			className={className}
			{...(props?.state && { state: props.state })}
		>
			{children}
		</Link>
	);

	if (props.href.startsWith('https://') || props.href.startsWith('http://')) {
		LinkWrapper = ({ children, className }) => (
			<a
				rel="noopener noreferrer"
				className={className}
				href={props.href}
				target="_blank"
			>
				{children}
			</a>
		);
	}

	return (
		<LinkWrapper
			className={cx(
				'flex items-center p-2 text-white hover:bg-gray-800 transition-colors rounded-md text-sm leading-5',
				props.isActive && 'bg-gray-800',
			)}
		>
			<div className="mr-3 flex h-6 w-6 items-center overflow-visible">
				{props?.icon && (
					<props.icon
						className={cx(
							'text-gray-300 flex-shrink-0 h-6 w-6',
							props.isActive && 'text-primary-300',
						)}
					/>
				)}
			</div>
			<span className="text-xs font-medium">{props.label}</span>
		</LinkWrapper>
	);
};

export const Links = ({ navigation, isBordered }) => {
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();

	return (
		<nav
			className={twMerge(
				'flex flex-col flex-1 mx-2 space-y-1',
				isBordered && 'border-y border-gray-700 py-1',
			)}
		>
			{navigation.map((nav) => (
				<AnimatedLink
					{...nav}
					key={nav?.fullPath ?? nav.href}
					href={nav.href}
					isActive={
						getActiveState(nav.href, pathname) &&
						!searchParams.get('highlightSetupLink')
					}
				/>
			))}
		</nav>
	);
};
