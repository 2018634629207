import { StorefrontStatus } from '~graphql/api/hooks';
import { StatusBadge } from '.';

export const StoreFrontStatusBadge = ({ store, ...props }) => {
	const label =
		store?.storefront?.status === StorefrontStatus['Published'] &&
		!store?.hasUnpublishedStorefrontChanges
			? 'Live'
			: 'Draft';

	const status =
		store?.storefront?.status === StorefrontStatus['Published'] &&
		!store?.hasUnpublishedStorefrontChanges
			? 'success'
			: 'warning';

	return <StatusBadge labelIcon label={label} status={status} {...props} />;
};
