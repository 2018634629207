import { Disclosure } from '@headlessui/react';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { CaretUp } from 'phosphor-react';

type SetupStepDisclosureProps = {
	children: JSX.Element | JSX.Element[];
	title: string;
	description: string;
	defaultOpen: boolean | undefined;
};

export const SetupStepDisclosure = ({
	children,
	title,
	description,
	defaultOpen,
}: SetupStepDisclosureProps) => (
	<Disclosure
		// This is the only way the Disclosure Component rerenders when the updated defaultOpen value changes
		key={defaultOpen ? defaultOpen.toString() : ''}
		defaultOpen={defaultOpen}
	>
		{({ open }) => (
			<div className="flex w-full">
				<div className="mb-4 w-full overflow-hidden rounded-lg bg-gray-50">
					<Disclosure.Button className="flex w-full flex-col justify-between px-5 pt-4 text-left text-sm font-medium">
						<div
							className={cx(
								'w-full mb-4',
								open && 'border-b border-gray-200 pb-6 mb-6',
							)}
						>
							<div className="flex w-full flex-row items-center justify-between text-gray-900">
								<span className="text-lg font-bold">{title}</span>
								<CaretUp
									className={`${!open && 'rotate-180'} h-5 w-5 text-gray-400`}
								/>
							</div>
							<span className="max-w-prose text-sm font-normal leading-5 text-gray-500">
								{description}
							</span>
						</div>
					</Disclosure.Button>
					<AnimatePresence>
						{open && (
							<motion.div
								initial={{
									height: 0,
									opacity: 0,
								}}
								animate={{
									height: 'auto',
									opacity: 1,
								}}
								exit={{
									height: 0,
									opacity: 0,
									marginTop: '0px',
									marginBottom: '0px',
								}}
								className="mt-2 mb-6 flex flex-col gap-5 px-5 text-sm text-gray-500"
							>
								{children}
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		)}
	</Disclosure>
);
