import { useAuth0 } from '@auth0/auth0-react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { User, SignOut, ArrowsLeftRight } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';
import { Button, ButtonVariant } from '~components/atoms';
import { useUser } from '~context/UserProvider';

const ProfilePictureFallback = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_9545_211218)">
			<rect width="36" height="36" rx="18" fill="#BAE6FD" />
			<path
				d="M2 38C2 29.1634 9.16344 22 18 22V22C26.8366 22 34 29.1634 34 38V38H2V38Z"
				fill="#1F2937"
			/>
			<ellipse cx="18" cy="14" rx="6" ry="7" fill="#1F2937" />
		</g>
		<defs>
			<clipPath id="clip0_9545_211218">
				<rect width="36" height="36" rx="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const ProfilePicture = ({ isLarge = false }) => {
	const { user, isLoading } = useUser();

	const baseClassname = twMerge(
		'rounded-full',
		isLarge ? 'h-[38px] w-[38px]' : 'h-[32px] w-[32px]',
	);

	if (isLoading) {
		return (
			<div className={twMerge(baseClassname, 'bg-gray-100 animate-pulse')} />
		);
	}

	if ((user as any)?.picture) {
		return (
			<img
				src={(user as any)?.picture}
				className={baseClassname}
				alt="user profile"
			/>
		);
	}

	return <ProfilePictureFallback />;
};

export const Profile = ({ isMinimal = false }) => {
	const navigate = useNavigate();
	const user = useUser();
	const { logout } = useAuth0();

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<div
					className={twMerge(
						'flex w-full items-center rounded-lg group cursor-pointer',
						!isMinimal && 'p-3',
					)}
				>
					<div
						className={twMerge(
							'border border-transparent transition-colors group-hover:border-secondary-500 rounded-full outline-none',
							isMinimal && 'min-h-[32px] min-w-[32px]',
						)}
					>
						<span className="sr-only">Open user menu</span>
						<ProfilePicture isLarge={!isMinimal} />
					</div>
					{!isMinimal && (
						<div className="ml-3 overflow-hidden truncate text-xs font-medium leading-4 text-white group-hover:text-gray-200">
							My account
						</div>
					)}
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					side="right"
					align="start"
					sideOffset={4}
					className={twMerge(
						'relative bottom-2',
						!isMinimal && 'bottom-4 left-[-200px]',
					)}
				>
					<div className="z-10 rounded bg-white shadow-lg focus:outline-none">
						<DropdownMenu.Item
							key="manage-account"
							onClick={() => {
								navigate('/account');
							}}
							className="block w-full cursor-pointer px-4 py-2 text-xs text-gray-700 transition-colors hover:bg-gray-100 hover:text-gray-900"
						>
							<div className="flex items-center">
								<User className="mr-3 text-gray-400" />
								Profile settings
							</div>
						</DropdownMenu.Item>
						<DropdownMenu.Item
							key="organizations"
							onClick={() => {
								navigate('/organizations');
							}}
							className="block w-full cursor-pointer px-4 py-2 text-xs text-gray-700 transition-colors hover:bg-gray-100 hover:text-gray-900"
						>
							<div className="flex items-center">
								<ArrowsLeftRight className="mr-3 text-gray-400" />
								Switch organization
							</div>
						</DropdownMenu.Item>
						<DropdownMenu.Label asChild>
							<div className="flex justify-between border-t border-gray-100 px-4 py-3">
								<div className="mr-4 flex flex-col">
									<span className="text-xs font-medium text-gray-700">
										{user.username}
									</span>
									<span className="text-xs font-thin text-gray-500">
										{user.user?.email}
									</span>
								</div>
								<Button
									onClick={() => logout()}
									variant={ButtonVariant.UNSTYLED}
									className="p-0"
								>
									<SignOut className="h-5 w-5 text-gray-400" />
								</Button>
							</div>
						</DropdownMenu.Label>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
