import { useMemo } from 'react';

import { AnimatePresence, m } from 'framer-motion';
import {
	Book,
	Code,
	Sliders,
	Suitcase,
	Storefront,
	TreeStructure,
	GearSix,
} from 'phosphor-react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { OrganizationProvider, StoreProvider } from '~context';
import { getActiveState } from '~lib';
import { fullScreenRoutes } from '~routing';
import { Scrollable } from '~components/atoms';
import { RoleEnum, useAccessManagement } from '~hooks/useAccessManagement';

import { GuidedStoreSetup } from './components/GuidedStoreSetup';
import { Links } from './components/Navigation';
import { Profile } from './components/Profile';

const basenav = [
	{
		label: 'Overview',
		href: 'home',
		icon: Storefront,
	},
	{
		label: 'Product options',
		href: 'products',
		icon: Suitcase,
		items: [
			{ label: 'Color options', href: 'products', isProtected: true },
			{
				label: 'Product personalization',
				href: 'products/personalizations',
			},
			{ label: 'Dynamic variants', href: 'products/groups', isProtected: true },
		],
	},
	{
		label: 'Integrations',
		href: 'integrations',
		icon: TreeStructure,
		isProtected: true,
	},
	{
		label: 'Custom code',
		href: 'custom-code',
		icon: Code,
		isProtected: true,
	},
	{
		label: 'Settings',
		href: 'settings',
		icon: Sliders,
		items: [
			{ label: 'General', href: 'settings', isProtected: true },
			{ label: 'Domains', href: 'settings/domains', isProtected: true },
			{
				label: 'Redirects',
				href: 'settings/redirects',
				isProtected: true,
			},
			{ label: 'System', href: 'settings/system' },
			{
				label: 'Shipping zones',
				href: 'settings/shipping-zones',
				isProtected: true,
			},
		],
	},
];

export const RouteAwareSidebar = () => {
	const location = useLocation();

	const isFullScreen = fullScreenRoutes.find((route) =>
		getActiveState(route.path, location.pathname),
	);

	return (
		<AnimatePresence mode="wait" initial={false}>
			<Routes
				// this key should be composed of wether or not the sidebar is hidden
				key={isFullScreen ? 'fullscreen' : 'sidebar'}
				location={location}
			>
				<Route path="*" element={<BaseSidebar />} />

				{/** hide for these paths */}
				{fullScreenRoutes.map(({ fullPath }) => (
					<Route path={`${fullPath}`} element={null} key={fullPath} />
				))}
			</Routes>
		</AnimatePresence>
	);
};

const SecondaryLinks = () => {
	const params = useParams();
	const location = useLocation();

	const { isAllowed } = useAccessManagement({
		rolesToEnforce: [RoleEnum.ADMIN, RoleEnum.OWNER],
	});

	const protectedNavigation = useMemo(
		() =>
			[
				typeof isAllowed !== 'undefined' &&
					isAllowed && {
						label: 'Organization settings',
						href: `../${params.organization}/manage/team`,
						icon: GearSix,
						state: { from: location.pathname },
					},
				{
					label: 'Documentation',
					href: import.meta.env.VITE_DOCUMENTATION_URL,
					icon: Book,
				},
			].filter(Boolean),
		[isAllowed, location.pathname, params.organization],
	);

	return (
		<div>
			<Links navigation={protectedNavigation} isBordered />
		</div>
	);
};

export const BaseSidebar = () => {
	const location = useLocation();

	const params = useParams();
	const isRoot =
		location.pathname === '/' || typeof params.store === 'undefined';

	return (
		<m.div
			layoutId="sidebar"
			exit={{
				opacity: 0,
				display: 'none',
			}}
			animate={{
				opacity: 1,
				display: 'flex',
			}}
			initial={{
				opacity: 0,
				display: 'none',
			}}
			transition={{ stiffness: 0.8, duration: 0.2 }}
			className="flex h-full max-h-full w-[256px] shrink-0 flex-col overflow-hidden bg-gray-900 text-gray-50"
		>
			<AnimatePresence mode="wait" initial={false}>
				<Routes
					// this key should be composed of wether or not the sidebar is hidden
					key={isRoot ? 'organization' : 'store'}
					location={location}
				>
					<Route path=":organization/*" element={<OrganizationSidebar />} />
					<Route
						path=":organization/stores/:store/*"
						element={<StoreSidebar />}
					/>
				</Routes>
			</AnimatePresence>

			<SecondaryLinks />

			<Profile />
		</m.div>
	);
};

export const OrganizationSidebar = () => {
	const navigation = [
		{
			label: 'Storefronts',
			href: 'stores',
			icon: Storefront,
		},
	];

	return (
		<div className="flex flex-1 flex-col py-5">
			<Links navigation={navigation} />
		</div>
	);
};

export const StoreSidebar = () => {
	const params = useParams();
	const { isAllowed } = useAccessManagement({
		rolesToEnforce: [RoleEnum.ADMIN, RoleEnum.OWNER],
	});

	const navigation = useMemo(
		() =>
			basenav.reduce((acc, item) => {
				if (typeof isAllowed !== 'undefined' && !isAllowed) {
					if (item.isProtected) {
						return acc;
					}

					if (item.items) {
						return [
							...acc,
							{
								...item,
								items: item.items.filter((subItem) => !subItem.isProtected),
							},
						];
					}
				}

				return [...acc, item];
			}, [] as typeof basenav),
		[isAllowed],
	);

	return (
		<div className="flex h-full max-h-full flex-col overflow-hidden">
			<OrganizationProvider>
				<StoreProvider storeSlug={params.store}>
					<GuidedStoreSetup />
				</StoreProvider>
			</OrganizationProvider>

			<div className="h-full max-h-full overflow-hidden">
				<Scrollable>
					<div className="h-full max-h-full py-5">
						<Links navigation={navigation} />
					</div>
				</Scrollable>
			</div>
		</div>
	);
};
