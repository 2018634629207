import cx from 'classnames';
import { motion } from 'framer-motion';

type ProgressProps = {
	icon?: JSX.Element;
	percentage?: number;
	darkMode?: boolean;
};

export const Progress = ({
	icon,
	percentage,
	darkMode = false,
}: ProgressProps) => (
	<div className="h-12 w-12 shrink-0">
		<svg className="h-12 w-12 rotate-[270deg]" viewBox="0 0 48 48">
			<circle
				r="20"
				cx="50%"
				cy="50%"
				strokeWidth={3}
				className={cx(darkMode ? 'stroke-gray-700' : 'stroke-gray-200')}
				fill="none"
				pathLength={100}
			/>
			{percentage && (
				<motion.circle
					initial={{ pathLength: 0 }}
					animate={{ pathLength: percentage / 100 }}
					transition={{
						duration: 1,
						stiffness: 0.8,
					}}
					r="20"
					cx="50%"
					cy="50%"
					strokeWidth={3}
					strokeLinecap="round"
					strokeDasharray={100}
					fill="none"
					className="h-12 w-12 fill-none stroke-primary-500"
				/>
			)}
			<text
				dx="50%"
				dy="50%"
				y="1px"
				textAnchor="middle"
				dominantBaseline="middle"
				fill="currentColor"
				stroke="#000"
				className={cx(
					'rotate-90 text-[10px] origin-center font-light stroke-[0.2]',
					icon && 'text-[20px]',
					darkMode ? 'text-white' : 'text-primary-900',
				)}
			>
				{percentage !== undefined ? `${percentage}%` : icon}
			</text>
		</svg>
	</div>
);
