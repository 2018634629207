import { FailedToFetch } from '../FailedToFetch';
import { GeneralError } from '../General';

type ErrorFallbackProps = {
	error: Error;
	resetErrorBoundary: () => void;
};

export const ErrorFallback = ({
	error,
	resetErrorBoundary,
}: ErrorFallbackProps) => {
	// Failed to fetch dynamically imported module occurs when the user is using a page while a new update has been deployed
	if (error.message.startsWith('Failed to fetch dynamically imported module')) {
		return <FailedToFetch handleReset={resetErrorBoundary} />;
	}

	// any other (generic) errors we handle here
	return <GeneralError handleReset={resetErrorBoundary} />;
};
