import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';

import { Loader } from '~components';
import { useOrganization } from '~context/OrganizationProvider';
import { useUser } from '~context/UserProvider';

type OrganizationRouteProps = {
	children: ReactNode;
};

export const OrganizationRoute = ({ children }: OrganizationRouteProps) => {
	const params = useParams();
	const { user } = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const { organization, isLoading } = useOrganization();

	useEffect(() => {
		if (!Boolean(organization) && !isLoading) {
			if (user && Boolean(user?.organizations?.length)) {
				const to =
					user?.organizations?.length > 1
						? '/organizations'
						: `/${user?.organizations?.[0].slug}/stores`;
				navigate(to, { replace: true });
			}
		}
	}, [isLoading, organization]);

	useEffect(() => {
		if (location.pathname === `/${params?.organization}`) {
			navigate(`/${params?.organization}/stores`, { replace: true });
		}
	}, [location]);

	if (isLoading) return <Loader />;

	if (
		organization?.numberOfTrialDaysLeft === 0 &&
		!organization.stripeSubscriptionId &&
		location.pathname !== `/${organization.slug}/trial-ended`
	) {
		return <Navigate to={`/${organization.slug}/trial-ended`} />;
	}

	return <>{children}</>;
};
