import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import { CheckCircle, X, WarningCircle } from 'phosphor-react';
import {
	toast as toastFn,
	ToastOptions as LibToastOptions,
} from 'react-hot-toast';

interface ToastOptions extends LibToastOptions {
	description?: string;
	type?: 'error' | 'success' | 'info';
}

export const toast = (
	title: string,
	{ description, type = 'info', ...options }: ToastOptions = {},
) =>
	toastFn.custom(
		(t) => (
			<Transition
				show={t.visible}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
					<div className="p-4">
						<div className="flex items-start">
							{!!type && (
								<div className="shrink-0">
									{type !== 'success' ? (
										<WarningCircle
											className={cx(
												type === 'error' ? 'text-red-600' : 'text-gray-500',
												'h-6 w-6 text-red-500',
											)}
											aria-hidden
										/>
									) : (
										<CheckCircle
											className="h-6 w-6 text-green-400"
											aria-hidden
										/>
									)}
								</div>
							)}
							<div className="ml-3 w-0 flex-1 pt-0.5">
								<p className="text-sm font-medium text-gray-900">{title}</p>
								{!!description && (
									<p className="mt-1 text-sm text-gray-500">{description}</p>
								)}
							</div>
							<div className="ml-4 flex shrink-0">
								<button
									type="button"
									className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
									onClick={() => {
										toastFn.dismiss(t.id);
									}}
								>
									<span className="sr-only">Close</span>
									<X aria-hidden />
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		),
		{
			position: 'top-right',
			...options,
		},
	);
