import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '~context';
import { useQueryKeyWrapper, useQueryWrapper } from '~hooks';
import {
	ChecklistStep,
	useFetchStoreSetupChecklistQuery,
	useFetchOrganizationChecklistQuery,
	useUpdateStoreChecklistMutation,
	useUpdateOrganizationChecklistMutation,
	ChecklistStatus,
} from '~graphql/api/hooks';
import { extractErrorMsg } from '~lib/extractErrorMsg';
import { handlePromise } from '~lib/handlePromise';
import { toast } from '~lib/toast';

export const useGuidedStoreSetup = () => {
	const queryClient = useQueryClient();
	const store = useStore();
	const [nextStep, setNextStep] = useState<string | undefined>(
		ChecklistStep.ConnectShopify,
	);

	const { data: storeData } = useQueryWrapper(useFetchStoreSetupChecklistQuery)(
		{},
		{ enabled: !!store?.store },
	);
	const { data: organizationData } = useQueryWrapper(
		useFetchOrganizationChecklistQuery,
	)();
	const STORE_CHECKLIST_QUERY_KEY = useQueryKeyWrapper(
		useFetchStoreSetupChecklistQuery.getKey,
	)();
	const ORGANIZATION_CHECKLIST_QUERY_KEY = useQueryKeyWrapper(
		useFetchOrganizationChecklistQuery.getKey,
	)();

	const updateStoreChecklistMutation = useUpdateStoreChecklistMutation({
		onSettled: () => {
			queryClient.invalidateQueries(STORE_CHECKLIST_QUERY_KEY);
		},
	});
	const updateOrganizationChecklistMutation =
		useUpdateOrganizationChecklistMutation({
			onSettled: () => {
				queryClient.invalidateQueries(ORGANIZATION_CHECKLIST_QUERY_KEY);
				queryClient.invalidateQueries(STORE_CHECKLIST_QUERY_KEY);
			},
		});

	const updateOrganizationChecklistStep = async (
		checklistStep: ChecklistStep,
		isSkipped: boolean = true,
	) => {
		const [data, error] = await handlePromise(
			updateOrganizationChecklistMutation.mutateAsync({
				input: {
					isSkipped,
					checklistStep,
				},
			}),
		);

		if (!data || error) {
			toast(extractErrorMsg(error).message, {
				type: 'error',
				position: 'bottom-center',
			});
		}
	};

	const updateStoreChecklistStep = async (
		checklistStep: ChecklistStep,
		isSkipped: boolean = true,
	) => {
		const [data, error] = await handlePromise(
			updateStoreChecklistMutation.mutateAsync({
				input: {
					isSkipped,
					checklistStep,
				},
			}),
		);

		if (!data || error) {
			toast(extractErrorMsg(error).message, {
				type: 'error',
				position: 'bottom-center',
			});
		}
	};

	const skipChecklistStep = async (checklistStep: ChecklistStep) => {
		if (checklistStep === ChecklistStep.InviteTeamMembers) {
			await updateOrganizationChecklistStep(checklistStep);
			return;
		}
		await updateStoreChecklistStep(checklistStep);
	};

	const setChecklistStepDone = async (checklistStep: ChecklistStep) =>
		await updateStoreChecklistStep(checklistStep, false);

	const skipOnboardingFlow = async (skipOnboardingFlow: boolean = true) =>
		await updateOrganizationChecklistStep(
			ChecklistStep.CreateStore,
			skipOnboardingFlow,
		);

	const getNextStep = (): string | undefined => {
		if (!storeData) {
			return ChecklistStep.ConnectShopify;
		}

		const { connect, build, deploy } = storeData.fetchStoreSetupChecklist;
		const sortedStoreData: {
			[key: string]: ChecklistStatus | undefined;
		} = {
			[ChecklistStep.ConnectShopify]: connect.connectShopify,
			[ChecklistStep.ConnectStoryblok]: connect.connectStoryblok,
			[ChecklistStep.InviteTeamMembers]: build?.inviteTeamMembers,
			[ChecklistStep.AddBrandAssets]: build?.addBrandAssets,
			[ChecklistStep.AddStorefrontBlock]: build?.addStoreFrontBlock,
			[ChecklistStep.EditStorefrontPage]: build?.editStoreFrontPage,
			[ChecklistStep.PublishStorefront]: build?.publishStoreFront,
			[ChecklistStep.ConfigureLanguages]: deploy?.configureLanguages,
			[ChecklistStep.SetupRedirects]: deploy?.setupRedirects,
			[ChecklistStep.ConfigureHeadlessTheme]: deploy?.configureHeadlessTheme,
			[ChecklistStep.FinalChecklist]: deploy?.finalCheck,
			[ChecklistStep.Launch]: deploy?.launch,
		};

		const [nextStep] = Object.entries(sortedStoreData).find(
			(value) => value[1] === ChecklistStatus.NotVisited,
		) ?? [undefined];

		return nextStep;
	};

	useEffect(() => {
		setNextStep(getNextStep());
	}, [storeData]);

	return {
		onboardingFlowSkipped:
			organizationData?.fetchOrganizationChecklist.connect.createStore ===
				'SKIPPED' || !getNextStep(),
		checklistState: storeData?.fetchStoreSetupChecklist,
		skipChecklistStep,
		skipOnboardingFlow,
		setChecklistStepDone,
		nextStep,
		STORE_CHECKLIST_QUERY_KEY,
		ORGANIZATION_CHECKLIST_QUERY_KEY,
	};
};
